import { sendUpdateEvent } from "../../../App"
import { MODULE_EVENTS } from "../../../common/moduleEvents"

/**
 *
 */
export async function listJsonConfigs() {
	sendUpdateEvent(MODULE_EVENTS.LIST_JSON_CONFIGS, {})
}

/**
 *
 */
export async function requestJsonConfig(configName: string) {
	sendUpdateEvent(MODULE_EVENTS.REQUEST_JSON_CONFIG, { configName })
}

/**
 *
 */
export async function updateJsonConfig(configName: string, configData: string) {
	sendUpdateEvent(MODULE_EVENTS.UPDATE_JSON_CONFIG, { configName, configData })
}
