import React, { memo, useContext, useMemo, useState } from 'react';
import { Updater } from 'use-immer';

import { Badge, Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../components/Header';
import Content, { ConnectedUsersBlock, ContentSection, ContentSpacer, GasBlock } from '../../components/Content';
import Sidebar, {
  SidebarButtonToggle,
  SidebarFilterButton,
  SidebarFilterRow,
  SidebarRootNavLinks,
  SidebarSection,
} from '../../components/Sidebar';

import { AppContext, AppState, sendUpdateEvent } from '../../App';
import DashboardTable from './DashboardTable';

import {
  BRAIN_EVENTS,
  DBAutomationMinCoeffRules,
  DBBridges,
  DBMiscSettings,
  DBTokens,
  filterActiveTokens,
  MODULE_EVENTS,
  MODULE_EVENTS_PARAMS,
  NETWORK,
  NETWORK_EXPLORER_ADDRESS,
  NETWORK_NATIVE_TOKEN,
  NETWORK_SOURCE_TOKEN,
  REQUESTER,
  Sell,
  WORKER_EVENTS,
} from '../../common';

import {
  selectPrivateKey,
  setWorkerTrackingValues,
  toggleGlobalAutomation,
  toggleRequester,
  toggleRequesterMode,
  updateSellBribe,
  updateSellGasPriceGwei,
  updateSellMinAmountOut,
} from './actions';

import { eye, eyeSlash } from '../../helper/svg';
import { getNetworkImage } from '../../assets/networks';
import BigNumber from 'bignumber.js';
import { getAggregatorImage } from '../../assets/aggregators';
import { handleRetrySellOrClaimClick, handleUpdateSellHash } from '../transactions';

/**
 * Filter data
 */
export type DashboardFilterData = {
  displayTokens: 'allall' | 'all' | 'unhidden' | 'hidden'
  dexPosition: 'left' | 'bottom' | 'none'
  displayRoutes: 'true' | 'false' | 'bn'
}
const defaultFilterData: DashboardFilterData = {
  displayTokens: 'unhidden',
  dexPosition: 'left',
  displayRoutes: 'false',
};

const ParseEventerStatString = (stats: string) => {
  const splitted = stats.split(" | ")
  // console.log(stats)
  return splitted.map(str => {
    const [networkName, status, _lastReceiveTime, _expectedEventSecDiff] = str.split(":")
    
    const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      sendUpdateEvent(MODULE_EVENTS.COMMAND, {
        to: "eventer",
        cmd: `connectOne~${networkName}~${250}`,
      })
    }

    const lastReceiveTime = parseInt(_lastReceiveTime || "0")
    const msDiff = Date.now() - lastReceiveTime
    const sDiff = parseInt((msDiff / 1000).toFixed(0))
    const sDiffAdj = Math.min(sDiff, 99)

    const expectedEventSecDiff = parseInt(_expectedEventSecDiff)

    let isWarn = status === "connected" && sDiffAdj > expectedEventSecDiff * 2
    let isError = status !== "connected" || (status === "connected" && sDiffAdj > expectedEventSecDiff * 4)

    const statusAdj = status === "connected" ? "conn" : status
    const sDiffAdjStr = sDiffAdj < 10 ? `0${sDiffAdj}` : `${sDiffAdj}`

    const bgColor = isError ? "danger" : isWarn ? "warning" : 'light'
    return <Badge onClick={(e) => onClick(e)} bg={bgColor} style={{ cursor: "pointer", color: isError ? "white" : "black" }}>
      <div style={{ display: "inline-block", backgroundColor: "white", padding: "2px", borderRadius: "100px" }}>
        <img src={getNetworkImage(networkName)} width={14} height={14} />
      </div>
      {' '}
      <p style={{ display: "inline-block", fontFamily: 'monospace', margin: '0' }}>{statusAdj}, {sDiffAdjStr}s</p>
      </Badge>
  })
}

const WorkerStatsString = memo(function WorkerStatsString() {
  const { workerStatsString, workerAnalyticsString, workerEventsString, eventerStatsString, workerLpStatString } =
    useContext(AppContext);
  const workerStatsStringSplitted = workerStatsString.split('\n');
  const workerEventsStringSplitted = workerEventsString.split('\n');
  const workerLpStatStringSplitted = workerLpStatString.split('\n');
  return (
    <div style={{ marginBottom: '16px' }}>
      {ParseEventerStatString(eventerStatsString)}
      {workerStatsStringSplitted.map((s, i) => (
        <p key={i} style={{ fontSize: '0.8em', marginBottom: '2px' }}>
          {s}
        </p>
      ))}
      <p style={{ fontSize: '0.8em' }}>{workerAnalyticsString}</p>
      {workerLpStatStringSplitted.map((s, i) => (
        <p key={i} style={{ fontSize: '0.8em' }}>
          {s}
        </p>
      ))}
      {workerEventsStringSplitted.map((s, i) => (
        <p key={i} style={{ fontSize: '0.8em' }}>
          {s}
        </p>
      ))}
    </div>
  );
});

type PendingSellsProps = {
  balance: DashboardProps['balance']
  sells: Sell[]
}
const PendingSells = memo(function PendingSells({ balance, sells }: PendingSellsProps) {
  const allowedSellBribeNetworks = [NETWORK.ETHEREUM, NETWORK.BINANCE];
  const showBribeButton = (network: NETWORK) => allowedSellBribeNetworks.includes(network);
  const showGasPriceButton = (network: NETWORK) => network !== NETWORK.SOLANA;

  const items = sells.map((s, idx) => {
    const {
      balance: sBalance,
      aggregatorAmountOut,
      minAmountOut,
      expectedAmountOut,
      bribe,
      sellStatus,
      claimStatus,
      isClaimable,
    } = s;

    let tokenBalance = balance[s.networkSell]?.[s.token.tokenName] ?? sBalance;
    tokenBalance = BigNumber(tokenBalance).isZero() ? '0' : tokenBalance;
    const sellBalance = BigNumber(sBalance).isZero() ? '0' : sBalance;
    const balanceChanged = tokenBalance !== sellBalance;

    const itemHeight = 22;
    const entryStyle = {
      display: 'flex', fontSize: '0.8em', marginRight: '4px',
      height: itemHeight, lineHeight: 1, fontWeight: 400,
    };
    const keyStyle = { fontWeight: 600, display: 'block', marginRight: '4px' };
    const valStyle = { display: 'block' };

    const etherIcon = <img style={{ display: 'block', marginLeft: '2px' }} width={12} height={12}
                           src={getNetworkImage('Ethereum')} />;

    const deltaDecim = (aggregatorAmountOut ?? 0) - (expectedAmountOut ?? 0);
    const delta = normalizeValueNum(deltaDecim);
    const deltaColor = delta >= 0 ? 'success' : 'danger';

    const claimWarning = isClaimable && claimStatus !== 'created';
    const claimColor = isClaimable
      ? claimWarning ? 'warning' : 'success'
      : 'secondary';

    const sellColor = sellStatus === 'skip' || sellStatus === 'stale' || sellStatus === 'failed'
      ? 'warning'
      : 'success';

    return (
      <div key={s.tradeId} style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
        <Badge bg="info" style={{
          fontSize: '0.8em', fontWeight: 600, marginRight: '4px', minWidth: '140px',
          display: 'flex', alignItems: 'center',
        }}>
          <img width="14px" height="14px" alt="" style={{ marginRight: '4px' }}
               src={getNetworkImage(s.networkSell)} />
          {s.tradeId}
        </Badge>

        <Badge bg={balanceChanged ? 'warning' : 'secondary'} style={{
          ...entryStyle, backgroundColor: balanceChanged ? '#ffcc00' : 'inherit',
        }}>
          <span style={valStyle}>{tokenBalance} {s.token.tokenName}</span>
        </Badge>

        <Badge style={{ ...entryStyle, maxWidth: '200px', cursor: 'pointer' }} onClick={() => {
          handleUpdateSellHash(s.tradeId);
        }}>
          <span style={valStyle}>{ellipsis(s.bridgeTxHash)}</span>
        </Badge>

        <Badge bg={sellColor} style={{ ...entryStyle, cursor: 'pointer' }} onClick={() => {
          if (sellStatus === 'pending' || sellStatus === 'created') return;
          handleRetrySellOrClaimClick(false, s.tradeId);
        }}>
          <span style={keyStyle}>Sell: </span>
          <span style={valStyle}>{sellStatus}</span>
        </Badge>

        <Badge bg={claimColor} style={{ ...entryStyle, cursor: 'pointer' }} onClick={() => {
          if (claimStatus === 'pending' || claimStatus === 'created') return;
          handleRetrySellOrClaimClick(true, s.tradeId);
        }}>
          <span style={keyStyle}>Claim: </span>
          <span style={valStyle}>{claimStatus}</span>
        </Badge>

        <Badge bg="secondary" style={entryStyle}>
          <span style={keyStyle}>{s.aggregatorType}: </span> <span
          style={valStyle}>{aggregatorAmountOut}</span>
          {etherIcon}
        </Badge>

        <Badge bg="secondary" style={entryStyle}>
          <span style={keyStyle}>Expected: </span> <span style={valStyle}>{expectedAmountOut}</span>
          {etherIcon}
        </Badge>

        <Badge bg={deltaColor} style={entryStyle}>
          <span style={keyStyle}>Δ: </span>
          <span style={valStyle}>{delta}</span>
          {etherIcon}
        </Badge>

        <Button
          onClick={() => updateSellMinAmountOut(s.tradeId, s.token.tokenName, s.minAmountOut ?? s.expectedAmountOut ?? 0)}
          color="secondary" size="sm"
          style={{ ...entryStyle, height: itemHeight, lineHeight: 0.9 }}>
          <span style={keyStyle}>Min:</span>
          <span style={valStyle}>{minAmountOut}</span>
          {etherIcon}
        </Button>

        {showBribeButton(s.networkSell) && (
          <Button
            onClick={() => updateSellBribe(s.tradeId, s.token.tokenName, s.bribe ?? 0)}
            color="secondary" size="sm"
            style={{ ...entryStyle, height: itemHeight, lineHeight: 0.9 }}>
            <span style={keyStyle}>Bribe:</span>
            <span style={valStyle}>{bribe}</span>
            {etherIcon}
          </Button>
        )}


        {showGasPriceButton(s.networkSell) && (
          <Button
            onClick={() => updateSellGasPriceGwei(s.tradeId, s.token.tokenName, s.gasPriceGwei ?? 0)}
            color="secondary" size="sm"
            style={{ ...entryStyle, height: itemHeight, lineHeight: 0.9 }}>
            <span style={keyStyle}>Gas Price:</span>
            <span style={valStyle}>{s.gasPriceGwei} gwei</span>
          </Button>
        )}
      </div>
    );
  });

  return (
    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'baseline', flexDirection: 'column' }}>
      <p style={{ fontSize: '0.8em', marginBottom: '4px', fontWeight: 600 }}>Sells</p>
      {items.map((Item) => Item)}
    </div>
  );
});

type LockedNoncesProps = {
  nonces: { [network in NETWORK]?: number | null }
}
const LockedNonces = memo(function LockedNonces({ nonces }: LockedNoncesProps) {
  const networks = Object.keys(nonces).filter(key => !!nonces[key as NETWORK]);

  return (
    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'baseline' }}>
      <p style={{ fontSize: '0.8em', marginRight: '4px' }}>[Locked Nonces]: </p>
      {networks.map((network) => (
        <div key={network} style={{ marginRight: '8px' }}>
          <img
            width="14px"
            height="14px"
            src={getNetworkImage(network)}
            alt=""
            style={{ marginRight: '4px' }}
          />
          <span style={{
            fontSize: '0.8em',
            fontWeight: 600,
            marginRight: '2px',
          }}>{nonces[network as NETWORK]}</span>
        </div>
      ))}
    </div>
  );
});

/**
 * Dashboard page
 *  Route '/'
 */
type DashboardProps = {
  workersRunning: AppState['brainState']['workersRunning']
  workersStartedBy: AppState['brainState']['workersStartedBy']
  pinnedTokens: AppState['brainState']['pinnedTokens']
  pinnedTokensNoTrade: AppState['brainState']['pinnedTokensNoTrade']
  autosellPins: AppState['brainState']['autosellPins']
  newHeads: AppState['newHeads']
  allTokens: DBTokens
  bridges: DBBridges
  trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
  trackingErrors: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_ERROR]
  selectedAccount: AppState['selectedAccount']
  miscSettings: AppState['miscSettings']
  connectedUsers: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS]
  gasEstimate: AppState['gasEstimate']
  balance: AppState['balance']
  automationRules?: AppState['automationRules']
  setAppState: Updater<AppState>
}
export default memo(function Dashboard({
                                         workersRunning,
                                         workersStartedBy,
                                         pinnedTokens,
                                         pinnedTokensNoTrade,
                                         autosellPins,
                                         newHeads,
                                         allTokens,
                                         bridges,
                                         trackingResults,
                                         trackingErrors,
                                         selectedAccount,
                                         miscSettings,
                                         connectedUsers,
                                         gasEstimate,
                                         balance,
                                         automationRules,
                                         setAppState,
                                       }: DashboardProps) {
  const { brainState: { pendingSells, lockedNonces } } = useContext(AppContext);
  const [textFilter, setTextFilter] = useState('');
  const [filterData, setFilterData] = useState<DashboardFilterData>(defaultFilterData);

  const workerTokens = useMemo(() => filterActiveTokens(allTokens), [allTokens]);
  const hasLockedNonces = useMemo(() => lockedNonces !== undefined && Object.values(lockedNonces).some(n => !!n), [lockedNonces]);
  const ethGasPrice = gasEstimate[NETWORK.ETHEREUM]?.gasHigh ?? -1;
  const minProfit = getMinProfit(ethGasPrice, automationRules?.minCoeffRules);

  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />

              <SidebarAccountSection
                selectedAccount={selectedAccount}
                setAppState={setAppState}
              />

              <SidebarAutomationSection
                globalAutomation={miscSettings?.globalAutomation}
                dashboardDisplayAllPinnedTokens={
                  miscSettings?.dashboardDisplayAllPinnedTokens
                }
              />

              <SidebarWorkerSection
                workerIncludeSlippage={miscSettings?.workerIncludeSlippage}
                workerTrackingValues={miscSettings?.workerTrackingValues}
                requesters={miscSettings?.requesters}
                requesterSettings={miscSettings?.requesterSettings}
              />

              <SidebarBalanceSection balance={balance} selectedAccount={selectedAccount} />

              <SidebarSection name="Display settings" gap={2}>
                <SidebarTextFilter textFilter={textFilter} setTextFilter={setTextFilter} />
                <SidebarDisplaySettingsSection
                  filterData={filterData}
                  setFilterData={setFilterData}
                />
              </SidebarSection>
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <p>
                🔵 Shift+click on slippage cell to set network slippage for green network.
                Shift+click on the right side of cell to set slippage for red network.
                <br />
                🔵 Cmd+Shift+click on slippage cell to set token slippage.
                <br />
                🟣 Cmd+Shift+click on network cell to ignore cell from tracking.
                <br />
                🟠 Shift+click on profit cell to pin token without trade creation.
              </p>
              {workersRunning && <p>Started by: {workersStartedBy}</p>}
              {<WorkerStatsString />}
              {!!pendingSells?.length && <PendingSells balance={balance} sells={pendingSells} />}
              {hasLockedNonces && <LockedNonces nonces={lockedNonces} />}
              <ContentSection>
                <ConnectedUsersBlock users={connectedUsers} />
                <GasBlock
                  value={ethGasPrice}
                  minProfit={minProfit}
                  otherNetworks={Object.entries(gasEstimate)
                    .filter(([n]) => n !== NETWORK.ETHEREUM)
                    .map(([n, d]) => ({
                      networkName: n,
                      value: d.gasHigh,
                    }))}
                />
              </ContentSection>
              <ContentSection renderP>
                <DashboardTable
                  workersRunning={workersRunning}
                  dashboardDisplayAllPinnedTokens={false}
                  pinnedTokens={pinnedTokens}
                  pinnedTokensNoTrade={pinnedTokensNoTrade}
                  autosellPins={autosellPins}
                  newHeads={newHeads}
                  gasEstimate={gasEstimate}
                  allTokens={allTokens}
                  workerTokens={workerTokens}
                  bridges={bridges}
                  trackingResults={trackingResults}
                  trackingErrors={trackingErrors}
                  textFilter={textFilter}
                  filterData={filterData}
                  miscSettings={miscSettings}
                />
              </ContentSection>
              <ContentSection>
                <ContentSpacer height={100} />
              </ContentSection>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});

/**
 *
 */
type SidebarAccountSectionProps = {
  selectedAccount: AppState['selectedAccount']
  setAppState: Updater<AppState>
}
const SidebarAccountSection = memo(function GetSidebarSections({
                                                                 selectedAccount,
                                                                 setAppState,
                                                               }: SidebarAccountSectionProps) {
  return (
    <SidebarSection name="Account">
      <SidebarButtonToggle
        text="Selected"
        value={
          selectedAccount.address
            ? selectedAccount.address.slice(0, 6) + '...' + selectedAccount.address.slice(-4)
            : 'none'
        }
      />
      <SidebarButtonToggle
        text="Private key"
        value={
          selectedAccount.privateKey
            ? selectedAccount.privateKey.slice(0, 4) +
            '...' +
            selectedAccount.privateKey.slice(-4)
            : '👉 select 👈'
        }
        onClick={() => selectPrivateKey(selectedAccount.privateKey, setAppState)}
      />
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarAutomationSectionProps = {
  globalAutomation?: boolean
  dashboardDisplayAllPinnedTokens?: boolean
}
const SidebarAutomationSection = memo(function SidebarAutomationSection({
                                                                          globalAutomation,
                                                                          dashboardDisplayAllPinnedTokens,
                                                                        }: SidebarAutomationSectionProps) {
  if (globalAutomation === undefined) {
    // if (globalAutomation === undefined || dashboardDisplayAllPinnedTokens === undefined) {
    return (
      <SidebarSection name="Automation">
        <SidebarButtonToggle text="⏳ Loading" />
        {/* <SidebarButtonToggle text="⏳ Loading" /> */}
      </SidebarSection>
    );
  }

  return (
    <SidebarSection name="Automation">
      <SidebarButtonToggle
        text={globalAutomation ? '🟢 Enabled' : '🔴 Disabled'}
        onClick={() => toggleGlobalAutomation(globalAutomation)}
      />
      {/* <SidebarButtonToggle
				text={dashboardDisplayAllPinnedTokens ? "📍 Display all token pins" : "📍 Display network pair pins"}
				onClick={() => toggleDashboardDisplayAllPinnedTokens(dashboardDisplayAllPinnedTokens)}
			/> */}
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarWorkerSectionProps = {
  workerIncludeSlippage?: boolean
  workerTrackingValues?: number[]
  requesters?: DBMiscSettings['requesters']
  requesterSettings?: DBMiscSettings['requesterSettings']
}
const SidebarWorkerSection = memo(function SidebarWorkerSection({
                                                                  workerIncludeSlippage,
                                                                  workerTrackingValues,
                                                                  requesters,
                                                                  requesterSettings,
                                                                }: SidebarWorkerSectionProps) {
  if (workerIncludeSlippage === undefined || workerTrackingValues === undefined) {
    return (
      <SidebarSection name="Worker">
        <SidebarButtonToggle text="⏳ Loading" />
      </SidebarSection>
    );
  }

  return (
    <SidebarSection name="Worker">
      {/* <SidebarButtonToggle
				text={workerIncludeSlippage ? "🟢 Include slippage" : "🔴 Exclude slippage"}
				onClick={() => toggleWorkerIncludeSlippage(workerIncludeSlippage)}
			/> */}
      <SidebarButtonToggle
        text="Tracking"
        value={workerTrackingValues.join(',')}
        onClick={() => setWorkerTrackingValues(workerTrackingValues)}
      />
      <div style={{ marginTop: '4px' }}>
        {Object.values(REQUESTER).map((r) => {
          const rTracking = requesterSettings?.[r]?.tracking || 'all';
          const rTrackingText = (() => {
            switch (rTracking) {
              case 'all':
                return 'A';
              case 'selected':
                return 'S';
                return '?';
            }
          })();
          return (
            <SidebarFilterButton
              key={r}
              style={{ margin: '2px', position: 'relative' }}
              text={
                <div>
                  <img width={14} src={getAggregatorImage(r)}></img>
                  <span
                    style={{
                      opacity: '0.6',
                      position: 'absolute',
                      bottom: '0px',
                      left: '0',
                      fontSize: '0.7em',
                    }}
                  >
										{rTrackingText}
									</span>
                </div>
              }
              active={requesters?.[r] ?? false}
              onClick={(e) => {
                const { shiftKey, metaKey } = e;
                if (shiftKey && metaKey) {
                  if (requesterSettings) {
                    toggleRequesterMode(r, requesterSettings);
                  }
                } else {
                  toggleRequester(r, requesters || {});
                }
              }}
            />
          );
        })}
      </div>
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarTextFilterProps = {
  textFilter: string
  setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
const SidebarTextFilter = memo(function SidebarTextFilter({
                                                            textFilter,
                                                            setTextFilter,
                                                          }: SidebarTextFilterProps) {
  return (
    <Form.Control
      size="sm"
      type="text"
      placeholder="token name"
      value={textFilter}
      onChange={(e) => setTextFilter(e.target.value)}
    />
  );
});

/**
 *
 */
type SidebarBalanceSectionProps = {
  balance: AppState['balance']
  selectedAccount: AppState['selectedAccount']
}
const SidebarBalanceSection = memo(function SidebarBalanceSection({
                                                                    balance,
                                                                    selectedAccount,
                                                                  }: SidebarBalanceSectionProps) {
  const networkSortOrder: NETWORK[] = [
    NETWORK.SOLANA,
    NETWORK.ETHEREUM,
    NETWORK.BINANCE,
    NETWORK.POLYGON,
    NETWORK.BASE,
    NETWORK.ARBITRUM,
    NETWORK.SNOWTRACE,
    NETWORK.OPTIMISM,
  ];

  const networkBalanceThresholds: { [network in NETWORK]?: number } = {
    [NETWORK.ARBITRUM]: 0.1,
    [NETWORK.BASE]: 0.1,
    [NETWORK.BINANCE]: 1,
    [NETWORK.ETHEREUM]: 0.5,
    [NETWORK.POLYGON]: 150,
    [NETWORK.SOLANA]: 0.2,
  };

  let totalEthBalance = BigNumber(0);
  for (const network of Object.keys(balance)) {
    totalEthBalance = totalEthBalance.plus(balance[network as NETWORK]?.['ETH'] ?? '0');
    totalEthBalance = totalEthBalance.plus(balance[network as NETWORK]?.['WETH'] ?? '0');
  }

  return (
    <SidebarSection name="Balance" gap={0} right={`${totalEthBalance.toFixed(3)}`}>
      {Object.entries(balance)
        .sort(([a], [b]) => {
          const indexA = networkSortOrder.indexOf(a as NETWORK);
          const indexB = networkSortOrder.indexOf(b as NETWORK);
          return indexA - indexB;
        })
        .map(([_networkName, networkBalances]) => {
          const networkName = _networkName as NETWORK;
          const nativeBalance = BigNumber(networkBalances[NETWORK_NATIVE_TOKEN[networkName]] || 0);
          const nativeBalanceStr = nativeBalance.toFixed(3);
          const thresholdReached =
            nativeBalance.comparedTo(0) !== 0 &&
            nativeBalance.comparedTo(networkBalanceThresholds[networkName]!) === -1;
          return (
            <SidebarButtonToggle
              key={networkName}
              text={
                <>
                  <Stack direction="horizontal" gap={1} style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignSelf: 'stretch',
                        paddingLeft: 4,
                        paddingRight: 4,
                        alignItems: 'center',
                        borderRadius: 4,
                        backgroundColor: thresholdReached ? '#ff8686' : 'transparent',
                      }}
                    >
                      <img
                        width="14px"
                        src={getNetworkImage(networkName)}
                        alt=""
                        style={{ marginRight: 4 }}
                      />
                      <span>
												<span>{nativeBalanceStr}</span>
												<span style={{ marginLeft: '4px', fontSize: '0.75em' }}>
													{NETWORK_NATIVE_TOKEN[networkName]}
												</span>
											</span>
                    </div>
                    <span style={{ width: '100%', textAlign: 'right' }}>
											<span>
												{BigNumber(
                          networkBalances[NETWORK_SOURCE_TOKEN[networkName][0]] || 0,
                        ).toFixed(3)}
											</span>
											<span style={{ marginLeft: '4px', fontSize: '0.75em' }}>
												{NETWORK_SOURCE_TOKEN[networkName][0]}
											</span>
										</span>
                  </Stack>
                </>
              }
              onClick={() => {
                window.open(
                  `${NETWORK_EXPLORER_ADDRESS[networkName]}/address/${selectedAccount.address}`,
                  '_blank',
                );
              }}
            />
          );
        })}
    </SidebarSection>
  );
});

/**
 *
 */
type SidebarDisplaySettingsSectionProps = {
  filterData: DashboardFilterData
  setFilterData: React.Dispatch<React.SetStateAction<DashboardFilterData>>
}
const SidebarDisplaySettingsSection = memo(function SidebarDisplaySettingsSection({
                                                                                    filterData,
                                                                                    setFilterData,
                                                                                  }: SidebarDisplaySettingsSectionProps) {
  return (
    <>
      <SidebarFilterRow text="Display">
        <SidebarFilterButton
          text="🦄"
          active={filterData.displayTokens === 'allall'}
          onClick={() => setFilterData({ ...filterData, displayTokens: 'allall' })}
        />
        <SidebarFilterButton
          text="All"
          active={filterData.displayTokens === 'all'}
          onClick={() => setFilterData({ ...filterData, displayTokens: 'all' })}
        />
        <SidebarFilterButton
          text={eye as any}
          active={filterData.displayTokens === 'unhidden'}
          onClick={() =>
            setFilterData({
              ...filterData,
              displayTokens: 'unhidden',
            })
          }
        />
        <SidebarFilterButton
          text={eyeSlash as any}
          active={filterData.displayTokens === 'hidden'}
          onClick={() =>
            setFilterData({
              ...filterData,
              displayTokens: 'hidden',
            })
          }
        />
      </SidebarFilterRow>
      <SidebarFilterRow text="Dex position">
        <SidebarFilterButton
          text="Left"
          active={filterData.dexPosition === 'left'}
          onClick={() =>
            setFilterData({
              ...filterData,
              dexPosition: filterData.dexPosition === 'left' ? 'none' : 'left',
            })
          }
        />
        <SidebarFilterButton
          text="Bottom"
          active={filterData.dexPosition === 'bottom'}
          onClick={() =>
            setFilterData({
              ...filterData,
              dexPosition: filterData.dexPosition === 'bottom' ? 'none' : 'bottom',
            })
          }
        />
      </SidebarFilterRow>
      <SidebarFilterRow text="Routes">
        <SidebarFilterButton
          text="True"
          active={filterData.displayRoutes === 'true'}
          onClick={() => setFilterData({ ...filterData, displayRoutes: 'true' })}
        />
        <SidebarFilterButton
          text="False"
          active={filterData.displayRoutes === 'false'}
          onClick={() => setFilterData({ ...filterData, displayRoutes: 'false' })}
        />
        <SidebarFilterButton
          text="BN"
          active={filterData.displayRoutes === 'bn'}
          onClick={() => setFilterData({ ...filterData, displayRoutes: 'bn' })}
        />
      </SidebarFilterRow>
    </>
  );
});

type MinCoefRule = {
  gasPrice: number
  minCoeff: number
}

const getMinProfit = (ethGasPrice?: number, minCoeffRules?: DBAutomationMinCoeffRules): number => {
  if (!ethGasPrice || ethGasPrice < 0 || !minCoeffRules) {
    return -1;
  }

  let rules: MinCoefRule[] = Object.keys(minCoeffRules).map((key) => {
    return {
      gasPrice: Number(key),
      minCoeff: minCoeffRules[Number(key)]!.minCoeff,
    };
  });
  rules.sort((a, b) => a.gasPrice - b.gasPrice);

  for (const { gasPrice, minCoeff } of rules || []) {
    if (ethGasPrice <= gasPrice) return minCoeff;
  }

  return -1;
};

const normalizeValueNum = (
  amount: number,
  maxDecimals: number = 4,
  minDecimals: number = 3,
  cmp: number = 0.01,
) => {
  return +normalizeValue(amount, maxDecimals, minDecimals, cmp);
};

const normalizeValue = (
  amount: number,
  maxDecimals: number = 6,
  minDecimals: number = 3,
  cmp: number = 0.01,
) => {
  if (amount === 0) return 0;
  return amount.toFixed(amount > cmp ? minDecimals : maxDecimals);
};

const ellipsis = (text: string, maxLength: number = 16) => {
  if ((text?.length ?? 0) > maxLength) {
    const start = text.substring(0, Math.ceil(maxLength / 2));
    const end = text.substring(text.length - Math.floor(maxLength / 2));
    return `${start}...${end}`;
  }
  return text;
};