import { Bounce, Id, toast, ToastContent, ToastOptions, ToastPromiseParams } from 'react-toastify';
import { CloseIcon } from './svg';

let toastCounter = 0;
let toastCloseAllTimeoutId: any = 0;
const closeAllToastId = 'closeAllToasts';

function CloseAllToastLink() {
  return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => toast.dismiss()}>
    <CloseIcon style={{ width: 18, height: 18 }} />
  </div>;
}

function CloseAllAnimation(props: any) {
  return <Bounce {...props} preventExitTransition />;
}

function updateCloseAllToast(hideImmediately = false) {
  if (hideImmediately) {
    toast.dismiss(closeAllToastId);
  }
  clearTimeout(toastCloseAllTimeoutId);
  toastCloseAllTimeoutId = setTimeout(() => {
    if (toastCounter >= 2) {
      toast(<CloseAllToastLink />, {
        autoClose: false,
        toastId: closeAllToastId,
        closeButton: false,
        className: 'close-all-toast',
        transition: CloseAllAnimation,
      });
    } else if (!hideImmediately) {
      toast.dismiss(closeAllToastId);
    }
  }, 500);
}

export const addToast = (content: ToastContent, opts: ToastOptions): Id => {
  return toast(content, {
    ...opts,
    onOpen() {
      toastCounter++;
      updateCloseAllToast(true);
    },
    onClose() {
      toastCounter = Math.max(0, toastCounter - 1);
      updateCloseAllToast(toastCounter < 2);
    },
  });
};

export const successToast = (content: ToastContent, opts?: ToastOptions): Id => addToast(content, {
  ...(opts ?? {}),
  type: 'success',
});
export const errorToast = (content: ToastContent, opts?: ToastOptions): Id => addToast(content, {
  ...(opts ?? {}),
  type: 'error',
});
export const warnToast = (content: ToastContent, opts?: ToastOptions): Id => addToast(content, {
  ...(opts ?? {}),
  type: 'warning',
});
export const infoToast = (content: ToastContent, opts?: ToastOptions): Id => addToast(content, {
  ...(opts ?? {}),
  type: 'info',
});
export const promiseToast = (promise: Promise<unknown>, params: ToastPromiseParams, opts?: ToastOptions): Promise<unknown> => {
  return toast.promise(promise, params, {
    ...opts,
    onOpen() {
      toastCounter++;
      updateCloseAllToast(true);
    },
    onClose() {
      toastCounter = Math.max(0, toastCounter - 1);
      updateCloseAllToast(toastCounter < 2);
    },
  });
};
