import BigNumber from 'bignumber.js';
import Swal from 'sweetalert2';

import Popup, { SwalStyled } from '../../../helper/Popup';
import { sendUpdateEvent } from '../../../App';
import {
  DBBridges,
  DBMiscSettings,
  DBToken,
  DBTokens,
  getTokenNetworkPairPoopAutomation,
  MODULE_EVENTS,
  NETWORK,
  REQUESTER,
  Route,
  RoutesData,
} from '../../../common';
import { getNetworkImage } from '../../../assets/networks';
import { getDexImage } from '../../../assets/dexes';
import { getDexNameAndVersion } from '../../../helper/misc';
import { getAggregatorImage } from '../../../assets/aggregators';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { createRoot, Root } from 'react-dom/client';

/**
 *
 */
export async function createToken(allTokens: DBTokens) {
  const result = await SwalStyled.fire({
    title: 'New token',
    input: 'text',
    inputPlaceholder: 'Token name',
    confirmButtonText: 'Create',
    allowEnterKey: true,
    inputValidator: (tokenNameInput) => {
      if (tokenNameInput.length === 0) return 'Token name can not be empty';
      if (!/^[a-zA-Z0-9]+$/.test(tokenNameInput)) return 'Token name must be alphanumeric only';
      if (
        Object.keys(allTokens)
          .map((t) => t.toLowerCase())
          .includes(tokenNameInput.toLowerCase())
      )
        return 'Token already exists';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const tokenName = result.value;

  sendUpdateEvent(MODULE_EVENTS.CREATE_TOKEN, { tokenName });
}

/**
 *
 */
export function displayTokenRoutes(tokenName: string, tokenKnownRoutes: RoutesData) {
  const displayRoute = (route: Route, bestReturnAmount: BigNumber) => {
    const [dexName, dexVersion] = getDexNameAndVersion(route.dexName);
    const thisReturnAmount = BigNumber(route.debug_returnAmount || '0');

    return (
      `<img width="14px" alt="" src="${getDexImage(
        dexName,
      )}" /><span class="ms-1" style="font-size: 0.8em;">v${dexVersion}</span>` +
      ': ' +
      (route.path || [])
        .map((t, i) => {
          if (i === 0) return t[0];
          return `${
            route.fees
              ? ' <span style="font-size: 0.6em;">' + route.fees[i - 1] / 10000 + '%</span>'
              : ' >'
          } ${t[0]}`;
        })
        .join('') +
      `<span class="ms-2" style="font-size: 0.7em;">${thisReturnAmount
        .multipliedBy(100)
        .dividedBy(bestReturnAmount)
        .toFixed(2)}%</span>`
    );
  };

  SwalStyled.fire({
    title: `${tokenName} routes`,
    showDenyButton: true,
    denyButtonText: 'Delete routes',
    html: `
			<div class="container">
				${Object.entries(tokenKnownRoutes)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(
        ([networkName, tokenNamePairs]) =>
          `<div class="row text-start">
								${Object.keys(tokenNamePairs)
            .map((key) => {
              const bestRoute = tokenNamePairs[key][0];
              const bestReturnAmount = BigNumber(
                bestRoute.debug_returnAmount || '0',
              );
              const keyText = key.split('_')[0] === tokenName ? 'Reverse' : 'Direct';
              return `<div class="row">
													<p class="fs-5 d-flex align-items-center gap-1"><img src="${getNetworkImage(
                networkName,
              )}" width="20px" alt="" />${keyText} (${
                Object.keys(tokenNamePairs[key]).length
              }):</p>
													${tokenNamePairs[key]
                .map(
                  (route) =>
                    `<div class="row ms-2">
																	<p class="fs-6">${displayRoute(route, bestReturnAmount)}</p>
																</div>`,
                )
                .join('')}
												</div>`;
            })
            .join('')}
							</div>`,
      )
      .join('')}
			</div>
		`,
  }).then((res) => {
    if (res.isDenied) {
      Popup.confirm({
        title: `Delete ${tokenName} routes?`,
        text: `This will delete known routes for token ${tokenName}`,
        onConfirm: () => {
          sendUpdateEvent(MODULE_EVENTS.DELETE_TOKEN_KNOWN_ROUTES, {
            tokenName,
            knownRoutes: tokenKnownRoutes,
          });
          Swal.close();
        },
      });
    }
  });
}

/**
 *
 */
export function updateTokenActive(tokenName: string, active: DBToken['active']) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { active: !active } });
}

/**
 *
 */
export function updateTokenAutomation(tokenName: string, automation: DBToken['automation']) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { automation: !automation } });
}

/**
 *
 */
export function updateTokenExpensiveBribe(tokenName: string, value: boolean) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { expensiveBribe: !value } });
}

/**
 *
 */
export function updateTokenCheapBribe(tokenName: string, value: boolean) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { cheapBribe: !value } });
}

/**
 *
 */
export function updateTokenNetworkPairPoopAutomation(
  tokenName: string,
  networkPairPoopAutomation: DBToken['networkPairPoopAutomation'],
  networkA: NETWORK,
  networkB: NETWORK,
  clickOn: 'general' | 'networkA' | 'networkB',
) {
  const curValue = getTokenNetworkPairPoopAutomation(networkPairPoopAutomation, networkA, networkB);
  const [n1, n2] = [networkA, networkB].sort((a, b) => a.localeCompare(b)) as [NETWORK, NETWORK];

  let newValue: true | NETWORK | null = curValue === true ? null : true;
  if (clickOn === 'networkA') {
    newValue = curValue === networkA ? null : networkA;
  }
  if (clickOn === 'networkB') {
    newValue = curValue === networkB ? null : networkB;
  }

  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkPairPoopAutomation: {
        ...networkPairPoopAutomation,
        [`${n1}_${n2}`]: newValue,
      },
    },
  });
}

/**
 *
 */
export function updateTokenHidden(tokenName: string, hidden: DBToken['hidden']) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { hidden: hidden } });
}

/**
 *
 */
export function updateTokenNetworkTaxable(
  tokenName: string,
  networkTokenTaxable: DBToken['networkTokenTaxable'],
  network: NETWORK,
  newValue: boolean,
) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkTokenTaxable: {
        ...networkTokenTaxable,
        [network]: newValue === true ? newValue : null,
      },
    },
  });
}

/**
 *
 */
export function updateTokenNetworkPairHidden(
  tokenName: string,
  hiddenNetworkEntries: DBToken['hiddenNetworkEntries'],
) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { hiddenNetworkEntries } });
}

/**
 *
 */
export function updateTokenNetworkPairAutomation(
  tokenName: string,
  currentValue: boolean,
  networkFrom: NETWORK,
  networkTo: NETWORK,
  tokenData: DBToken,
) {
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkPairAutomation: {
        ...tokenData.networkPairAutomation,
        [`${networkFrom}_${networkTo}`]: currentValue === true ? null : true,
      },
    },
  });
}

/**
 *
 */
export function updateTokenNetworkAddress(
  tokenName: string,
  networkName: NETWORK,
  networkData: DBToken['networkData'],
) {
  if (!Object.prototype.hasOwnProperty.call(networkData, networkName)) return;
  const currentValue = networkData?.[networkName]!.address ?? '';

  Popup.singleInput({
    title: `Edit ${tokenName} address`,
    value: currentValue,
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (newAddress) => {
      if (newAddress === '0x0') return null;
      // if (!isAddress(newAddress)) return "Not a valid address"
      return null;
    },
    onNewValue: (newAddress) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          networkData: {
            ...networkData,
            [networkName]: {
              ...(networkData?.[networkName] ?? {}),
              address: newAddress,
            },
          },
        },
      });
    },
  });
}

/**
 *
 */
export function updateTokenNetworkDecimals(
  tokenName: string,
  networkName: NETWORK,
  networkData: DBToken['networkData'],
) {
  if (!Object.prototype.hasOwnProperty.call(networkData, networkName)) return;
  const currentValue = (networkData?.[networkName]!.decimals ?? 0).toString();

  Popup.singleInput({
    title: `Edit ${tokenName} decimals`,
    value: currentValue,
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (isNaN(parseInt(v))) return 'Decimals must be a whole number';
      if (parseInt(v).toString() !== v) return 'Decimals must be a whole number';
      if (parseInt(v) < 0) return 'Decimals must be positive';
      return null;
    },
    onNewValue: (newDecimals) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          networkData: {
            ...networkData,
            [networkName]: {
              ...(networkData?.[networkName] ?? {}),
              decimals: parseInt(newDecimals),
            },
          },
        },
      });
    },
  });
}

export async function addTokenSelectedRequester(
  tokenName: string,
  selectedRequesters: DBToken['selectedRequesters'],
  miscSettings?: DBMiscSettings,
) {
  const allowedInputValues = Object.values(REQUESTER)
    .filter((r) => !(selectedRequesters?.[r] === true))
    .filter((r) => miscSettings?.requesterSettings[r]?.tracking === 'selected')
    .sort((a, b) => a.localeCompare(b));

  const numSuggestionToDisplay = 20;

  const renderSuggestions = (inputValue: string) => {
    const inputValueLower = inputValue.toLowerCase();
    const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
      v.toLowerCase().includes(inputValueLower),
    );

    const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
    const remainingKeys = allowedInputValuesFiltered.slice(
      numSuggestionToDisplay,
      allowedInputValuesFiltered.length,
    );

    const swalContainer = SwalStyled.getHtmlContainer()!;

    swalContainer.innerHTML = `
				<div class="container">
					${suggestionKeys
      .map(
        (name) =>
          `<span
									id="input-suggestion-${name}" 
									class="swalInputSuggestion badge text-dark bg-light m-1"
									style="display: inline-flex; align-items: center; gap: 4px;"
								>
									<img src="${getAggregatorImage(name)}" width="12px" />
									${name}
								</span>`,
      )
      .join('')}
					<span class="badge text-secondary bg-light">${
      remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
    }</span>
					<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
				</div>
			`;

    SwalStyled.resetValidationMessage();

    for (const name of suggestionKeys) {
      document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
        SwalStyled.getInput()!.value = name;
        SwalStyled.resetValidationMessage();
        renderSuggestions(name);
        SwalStyled.getInput()?.focus();
      });
    }
  };

  const result = await SwalStyled.fire({
    title: `Add ${tokenName} requester`,
    input: 'text',
    inputPlaceholder: `Requester key`,
    html: `<div class="container"></div>`,
    allowEnterKey: true,
    confirmButtonText: 'Save',
    didOpen: () => {
      renderSuggestions('');

      const inputField = SwalStyled.getInput();
      inputField!.oninput = (e) => {
        // @ts-ignore
        const inputValue: string = e.target.value;
        renderSuggestions(inputValue);
      };
    },
    inputValidator: (v) => {
      if (!allowedInputValues.includes(v as REQUESTER)) return 'Wrong requester key. Use suggestions';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const value: string = result.value;

  const newSelectedRequesters: DBToken['selectedRequesters'] = {
    ...selectedRequesters,
    [value]: true,
  };
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      selectedRequesters: newSelectedRequesters,
    },
  });
}

/**
 *
 */
export async function deleteTokenSelectedRequester(
  tokenName: string,
  requester: REQUESTER,
  selectedRequesters: DBToken['selectedRequesters'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} ${requester} requester`,
    text: `Are you sure want to delete ${tokenName} ${requester} requester?`,
    onConfirm: () => {
      let newSelectedRequesters = structuredClone(selectedRequesters ?? {});
      delete newSelectedRequesters[requester];

      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: { selectedRequesters: newSelectedRequesters },
      });
    },
  });
}

/**
 *
 */
export async function addTokenNetwork(tokenName: string, networkData: DBToken['networkData']) {
  const allowedNetworkValues = Object.values(NETWORK);
  const existingNetworkValues = Object.keys(networkData ?? {});

  const allowedInputValues = allowedNetworkValues
    .filter((v) => !existingNetworkValues.includes(v))
    .sort((a, b) => a.localeCompare(b));
  const numSuggestionToDisplay = 20;

  const renderSuggestions = (inputValue: string) => {
    const inputValueLower = inputValue.toLowerCase();
    const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
      v.toLowerCase().includes(inputValueLower),
    );

    const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
    const remainingKeys = allowedInputValuesFiltered.slice(
      numSuggestionToDisplay,
      allowedInputValuesFiltered.length,
    );

    const swalContainer = SwalStyled.getHtmlContainer()!;

    swalContainer.innerHTML = `
			<div class="container">
				${suggestionKeys
      .map(
        (name) =>
          `<span
								id="input-suggestion-${name}" 
								class="swalInputSuggestion badge text-dark bg-light m-1"
								style="display: inline-flex; align-items: center; gap: 4px;"
							>
								<img src="${getNetworkImage(name)}" width="12px" />
								${name}
							</span>`,
      )
      .join('')}
				<span class="badge text-secondary bg-light">${
      remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
    }</span>
				<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
			</div>
		`;

    SwalStyled.resetValidationMessage();

    for (const name of suggestionKeys) {
      document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
        SwalStyled.getInput()!.value = name;
        SwalStyled.resetValidationMessage();
        renderSuggestions(name);
        SwalStyled.getInput()?.focus();
      });
    }
  };

  const result = await SwalStyled.fire({
    title: `Add ${tokenName} network`,
    input: 'text',
    inputPlaceholder: `Network name`,
    html: `<div class="container"></div>`,
    allowEnterKey: true,
    confirmButtonText: 'Save',
    didOpen: () => {
      renderSuggestions('');

      const inputField = SwalStyled.getInput();
      inputField!.oninput = (e) => {
        // @ts-ignore
        const inputValue: string = e.target.value;
        renderSuggestions(inputValue);
      };
    },
    inputValidator: (v) => {
      if (!allowedInputValues.includes(v as NETWORK)) return 'Wrong network name. Use suggestions';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const value: string = result.value;

  const newNetworkData: NonNullable<DBToken['networkData']>['Ethereum'] = {
    // automation: false,
    address: '0x0',
    decimals: 0,
  };
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkData: {
        ...networkData,
        [value]: newNetworkData,
      },
    },
  });
}

/**
 *
 */
export async function deleteTokenNetwork(
  tokenName: string,
  networkName: NETWORK,
  networkData: DBToken['networkData'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} ${networkName} network`,
    text: `Are you sure want to delete ${tokenName} ${networkName} network?`,
    onConfirm: () => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          networkData: {
            ...networkData,
            [networkName]: null,
          },
        },
      });
    },
  });
}

/**
 *
 */
export function updateTokenTrackingValue(tokenName: string, trackingValue: DBToken['trackingValue']) {
  const currentValue = trackingValue.toString();

  Popup.singleInput({
    title: `Edit ${tokenName} value`,
    value: currentValue,
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (isNaN(parseFloat(v))) return 'Value must be a number';
      if (parseFloat(v).toString() !== v) return 'Value must be a number';
      if (parseFloat(v) < 0) return 'Value must be positive';
      return null;
    },
    onNewValue: (newValue) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: { trackingValue: parseFloat(newValue) },
      });
    },
  });
}

/**
 *
 */
export function updateTokenMinTrackingValue(tokenName: string, minValue: DBToken['minTrackingValue']) {
  const currentValue = (minValue || '').toString();

  Popup.singleInput({
    title: `Edit ${tokenName} minTrackingValue`,
    value: currentValue,
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (v === '') return null;
      if (isNaN(parseFloat(v))) return 'Value must be a number';
      if (parseFloat(v).toString() !== v) return 'Value must be a number';
      if (parseFloat(v) < 0) return 'Value must be positive';
      return null;
    },
    onNewValue: (newValue) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: { minTrackingValue: newValue === '' ? 0 : parseFloat(newValue) },
      });
    },
  });
}

/**
 *
 */
export function updateTokenMaxTrackingValue(tokenName: string, maxValue: DBToken['maxTrackingValue']) {
  const currentValue = (maxValue || '').toString();

  Popup.singleInput({
    title: `Edit ${tokenName} maxTrackingValue`,
    value: currentValue,
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (v === '') return null;
      if (isNaN(parseFloat(v))) return 'Value must be a number';
      if (parseFloat(v).toString() !== v) return 'Value must be a number';
      if (parseFloat(v) < 0) return 'Value must be positive';
      return null;
    },
    onNewValue: (newValue) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: { maxTrackingValue: newValue === '' ? 0 : parseFloat(newValue) },
      });
    },
  });
}

/**
 *
 */
export function updateTokenSlippage(tokenName: string, slippage: DBToken['slippage']) {
  const currentValue = slippage.toString();

  Popup.singleInput({
    title: `Edit ${tokenName} slippage`,
    value: slippage.toString(),
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      // if (v.endsWith("%")) {
      // 	v = v.slice(0, v.length - 1)
      // }
      if (isNaN(parseFloat(v))) return 'Slippage must be a number';
      if (parseFloat(v).toString() !== v) return 'Slippage must be a number';
      if (parseFloat(v) < 0) return 'Slippage must be positive';
      return null;
    },
    onNewValue: (newValue) => {
      let newValueUpd: string | number = newValue;
      if (newValueUpd.endsWith('%')) {
        newValueUpd = parseFloat(newValue.slice(0, newValue.length - 1)) + '%';
      } else {
        newValueUpd = parseFloat(newValue);
      }
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: { slippage: newValueUpd },
      });
    },
  });
}

/**
 *
 */
export async function addTokenTradeSlippage(
  tokenName: string,
  networkData: DBToken['networkData'],
  tradeSlippage: DBToken['tradeSlippage'],
) {
  const allowedNetworkValues = Object.keys(networkData || {});
  const existingNetworkValues = Object.keys(tradeSlippage ?? {});

  const allowedInputValues = allowedNetworkValues
    .filter((v) => !existingNetworkValues.includes(v))
    .sort((a, b) => a.localeCompare(b));
  const numSuggestionToDisplay = 20;

  const renderSuggestions = (inputValue: string) => {
    const inputValueLower = inputValue.toLowerCase();
    const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
      v.toLowerCase().includes(inputValueLower),
    );

    const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
    const remainingKeys = allowedInputValuesFiltered.slice(
      numSuggestionToDisplay,
      allowedInputValuesFiltered.length,
    );

    const swalContainer = SwalStyled.getHtmlContainer()!;

    swalContainer.innerHTML = `
			<div class="container">
				${suggestionKeys
      .map(
        (name) =>
          `<span
								id="input-suggestion-${name}" 
								class="swalInputSuggestion badge text-dark bg-light m-1"
								style="display: inline-flex; align-items: center; gap: 4px;"
							>
								<img src="${getNetworkImage(name)}" width="12px" />
								${name}
							</span>`,
      )
      .join('')}
				<span class="badge text-secondary bg-light">${
      remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
    }</span>
				<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
			</div>
		`;

    SwalStyled.resetValidationMessage();

    for (const name of suggestionKeys) {
      document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
        SwalStyled.getInput()!.value = name;
        SwalStyled.resetValidationMessage();
        renderSuggestions(name);
        SwalStyled.getInput()?.focus();
      });
    }
  };

  const result = await SwalStyled.fire({
    title: `Add ${tokenName} trade slippage`,
    input: 'text',
    inputPlaceholder: `Network name`,
    html: `<div class="container"></div>`,
    allowEnterKey: true,
    confirmButtonText: 'Save',
    didOpen: () => {
      renderSuggestions('');

      const inputField = SwalStyled.getInput();
      inputField!.oninput = (e) => {
        // @ts-ignore
        const inputValue: string = e.target.value;
        renderSuggestions(inputValue);
      };
    },
    inputValidator: (v) => {
      if (!allowedInputValues.includes(v as NETWORK)) return 'Wrong network name. Use suggestions';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const value: string = result.value;

  const newTradeSlippageData: NonNullable<DBToken['tradeSlippage']>['Ethereum'] = 0;
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      tradeSlippage: {
        ...tradeSlippage,
        [value]: newTradeSlippageData,
      },
    },
  });
}

/**
 *
 */
export async function addTokenNetworkSlippage(
  tokenName: string,
  networkData: DBToken['networkData'],
  networkSlippage: DBToken['networkPairSlippage'],
) {
  const allowedNetworkValues: string[] = [];
  for (const n1 of Object.keys(networkData || {})) {
    for (const n2 of Object.keys(networkData || {})) {
      if (n1 === n2) continue;
      const sorted = [n1, n2].sort((a, b) => a.localeCompare(b));
      const key = `${sorted[0]}_${sorted[1]}`;
      if (allowedNetworkValues.includes(key)) continue;
      allowedNetworkValues.push(`${n1}_${n2}`);
    }
  }
  const existingValues = Object.keys(networkSlippage || {});

  const allowedInputValues = allowedNetworkValues
    .filter((v) => !existingValues.includes(v))
    .sort((a, b) => a.localeCompare(b));
  const numSuggestionToDisplay = 20;

  const renderSuggestions = (inputValue: string) => {
    const inputValueLower = inputValue.toLowerCase();
    const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
      v.toLowerCase().includes(inputValueLower),
    );

    const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
    const remainingKeys = allowedInputValuesFiltered.slice(
      numSuggestionToDisplay,
      allowedInputValuesFiltered.length,
    );

    const swalContainer = SwalStyled.getHtmlContainer()!;

    swalContainer.innerHTML = `
			<div class="container">
				${suggestionKeys
      .map((name) => {
        const [n1, n2] = name.split('_');
        return `<span
								id="input-suggestion-${name}" 
								class="swalInputSuggestion badge text-dark bg-light m-1"
								style="display: inline-flex; align-items: center; gap: 4px;"
							>
								<img src="${getNetworkImage(n1)}" width="12px" />
								<img src="${getNetworkImage(n2)}" width="12px" />
								${name}
							</span>`;
      })
      .join('')}
				<span class="badge text-secondary bg-light">${
      remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
    }</span>
				<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
			</div>
		`;

    SwalStyled.resetValidationMessage();

    for (const name of suggestionKeys) {
      document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
        SwalStyled.getInput()!.value = name;
        SwalStyled.resetValidationMessage();
        renderSuggestions(name);
        SwalStyled.getInput()?.focus();
      });
    }
  };

  const result = await SwalStyled.fire({
    title: `Add ${tokenName} network slippage`,
    input: 'text',
    inputPlaceholder: `Network pair`,
    html: `<div class="container"></div>`,
    allowEnterKey: true,
    confirmButtonText: 'Save',
    didOpen: () => {
      renderSuggestions('');

      const inputField = SwalStyled.getInput();
      inputField!.oninput = (e) => {
        // @ts-ignore
        const inputValue: string = e.target.value;
        renderSuggestions(inputValue);
      };
    },
    inputValidator: (v) => {
      if (!allowedInputValues.includes(v as NETWORK)) return 'Wrong network name. Use suggestions';
      return null;
    },
  });

  if (!result.isConfirmed) return;
  const value: string = result.value;
  const [n1, n2] = value.split('_');

  const newNetworkPairSlippage: NonNullable<DBToken['networkPairSlippage']>['Arbitrum_Arbitrum'] = {
    [n1 as NETWORK]: -1,
    [n2 as NETWORK]: -1,
  };

  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkPairSlippage: {
        ...networkSlippage,
        [value]: newNetworkPairSlippage,
      },
    },
  });
}

/**
 *
 */
export async function addTokenMinProfit(
  tokenName: string,
  networks: NETWORK[],
  minRequiredProfit: DBToken['minRequiredProfit'],
) {
  SwalStyled.fire({
    title: `Add ${tokenName} Min Profit`,
    showConfirmButton: false,
    showCloseButton: true,
    width: '400px',
    html: '<div id="add-token-min-profit-form"></div>',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#add-token-min-profit-form',
      ) as HTMLElement;

      if (formContainer) {
        const root: Root = createRoot(formContainer);
        root.render(
          <AddTokenMinProfitForm
            tokenName={tokenName}
            networks={networks}
            minRequiredProfit={minRequiredProfit}
            onClose={() => SwalStyled.close()}
          />,
        );
        (SwalStyled as any)._addMinProfit = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#add-token-min-profit-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._addMinProfit) {
        const root: Root = (SwalStyled as any)._addMinProfit;
        root.unmount();
        delete (SwalStyled as any)._addMinProfit;
      }
    },
  });
}

interface AddTokenMinProfitFormProps {
  tokenName: string;
  networks: NETWORK[];
  minRequiredProfit: DBToken['minRequiredProfit'];
  onClose: () => void;
}

const AddTokenMinProfitForm: React.FC<AddTokenMinProfitFormProps> = (
  {
    tokenName,
    networks,
    minRequiredProfit,
    onClose,
  }) => {
  const [networkBuy, setNetworkBuy] = useState<NETWORK | null>(null);
  const [networkSell, setNetworkSell] = useState<NETWORK | null>(null);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const handleRetry = () => {
    if (!networks.includes(networkBuy!)) {
      setError('Wrong Buy Network');
      return;
    }
    if (!networks.includes(networkSell!)) {
      setError('Wrong Buy Network');
      return;
    }
    if (networkBuy === networkSell) {
      setError('Wrong networks');
      return;
    }

    const networkPair = `${networkBuy}_${networkSell}`;
    const existingPair = Object.keys(minRequiredProfit ?? {}).find(key => {
      return key === networkPair;
    });
    if (!!existingPair) {
      setError('Network pair already exists');
      return;
    }

    const minProfitValue = Number(value);
    if (Number.isNaN(minProfitValue) || minProfitValue < 0) {
      setError('Wrong value');
      return;
    }

    sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
      tokenName,
      updates: {
        minRequiredProfit: {
          ...(minRequiredProfit ?? {}),
          [networkPair]: value,
        },
      },
    });

    // Close the modal
    onClose();
  };

  return (
    <Form>
      <div className="container" style={{ marginBottom: '8px' }}>
        {networks.map(name => (
          <span
            key={name}
            style={{ display: 'inline-flex', alignItems: 'center', gap: '4px', marginRight: '4px', cursor: 'pointer' }}
            onClick={() => setNetworkBuy(name)}
          >
								<img src={getNetworkImage(name)} width="12px" />{name}
          </span>
        ))}
      </div>

      <Form.Control
        type="text"
        placeholder="Buy Network"
        value={networkBuy ?? ''}
        onChange={(e) => {
          setNetworkBuy(e.target.value as any);
          if (error) setError('');
        }}
        isInvalid={!!error}
        style={{ marginBottom: '8px' }}
      />

      <div className="container" style={{ marginBottom: '8px' }}>
        {networks.map(name => (
          <span
            key={name}
            style={{ display: 'inline-flex', alignItems: 'center', gap: '4px', marginRight: '4px', cursor: 'pointer' }}
            onClick={() => setNetworkSell(name)}
          >
								<img src={getNetworkImage(name)} width="12px" />{name}
          </span>
        ))}
      </div>

      <Form.Control
        type="text"
        placeholder="Sell Network"
        value={networkSell ?? ''}
        onChange={(e) => {
          setNetworkSell(e.target.value as any);
          if (error) setError('');
        }}
        style={{ marginBottom: '8px' }}
        isInvalid={!!error}
      />

      <Form.Control
        type="text"
        placeholder="Min Profit in WETH"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (error) setError('');
        }}
        style={{ marginBottom: '8px' }}
        isInvalid={!!error}
      />

      <Form.Control.Feedback type="invalid" style={{ marginBottom: '8px' }}>{error}</Form.Control.Feedback>
      <Button variant="primary" onClick={handleRetry}>
        Save
      </Button>
    </Form>
  );
};

/**
 *
 */
export async function deleteTokenTradeSlippage(
  tokenName: string,
  key: string,
  tradeSlippage: DBToken['tradeSlippage'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} ${key} trade slippage`,
    text: `Are you sure want to delete ${tokenName} ${key} trade slippage?`,
    onConfirm: () => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          tradeSlippage: {
            ...tradeSlippage,
            [key]: null,
          },
        },
      });
    },
  });
}

/**
 *
 */
export async function deleteTokenNetworkSlippage(
  tokenName: string,
  key: string,
  networkSlippage: DBToken['networkPairSlippage'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} ${key} slippage`,
    text: `Are you sure want to delete ${tokenName} ${key} slippage?`,
    onConfirm: () => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          networkPairSlippage: {
            ...networkSlippage,
            [key]: null,
          },
        },
      });
    },
  });
}

/**
 *
 */
export async function deleteTokenMinProfit(
  tokenName: string,
  networkPair: string,
  minRequiredProfit: DBToken['minRequiredProfit'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} min profit`,
    text: `Are you sure want to delete ${tokenName} min profit?`,
    onConfirm: () => {
      console.log(minRequiredProfit);
      const { [networkPair as never as keyof DBToken['minRequiredProfit']]: _, ...updated } = (minRequiredProfit ?? {});
      console.log(updated);
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          minRequiredProfit: updated,
        },
      });
    },
  });
}

/**
 *
 */
export async function editTokenTradeSlippage(
  tokenName: string,
  network: NETWORK,
  tradeSlippage: DBToken['tradeSlippage'],
) {
  const currentValue = ((tradeSlippage || {})[network] || 0).toString();

  Popup.singleInput({
    title: `Edit ${tokenName} ${network} trade slippage`,
    text: `Enter ${network} trade slippage.`,
    value: currentValue.toString(),
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (v.endsWith('%')) {
        v = v.slice(0, v.length - 1);
      }
      if (isNaN(parseFloat(v))) return 'Slippage must be a number';
      if (parseFloat(v).toString() !== v) return 'Slippage must be a number';
      if (parseFloat(v) < 0) return 'Slippage must be positive';
      return null;
    },
    onNewValue: (newValue) => {
      let newValueUpd: string | number = newValue;
      if (newValueUpd.endsWith('%')) {
        newValueUpd = parseFloat(newValue.slice(0, newValue.length - 1)) + '%';
      } else {
        newValueUpd = parseFloat(newValue);
      }

      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          tradeSlippage: {
            ...tradeSlippage,
            [network]: newValueUpd,
          },
        },
      });
    },
  });
}

/**
 *
 */
export async function editTokenNetworkSlippage(
  tokenName: string,
  key: string,
  network: NETWORK,
  networkSlippage: DBToken['networkPairSlippage'],
) {
  const currentValue = (
    ((networkSlippage || {})[key as keyof typeof networkSlippage]?.[network] as number) || -1
  ).toString();

  Popup.singleInput({
    title: `Edit ${tokenName} ${key} slippage`,
    text: `Enter ${network} slippage.<br/><br/>Enter -1 to use default slippage value`,
    value: currentValue.toString(),
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (v === '-1') return null;
      if (isNaN(parseFloat(v))) return 'Slippage must be a number';
      if (parseFloat(v).toString() !== v) return 'Slippage must be a number';
      if (parseFloat(v) < 0) return 'Slippage must be positive';
      return null;
    },
    onNewValue: (_newValue) => {
      const newValue = parseFloat(_newValue);

      const newNetworkPairSlippage: NonNullable<DBToken['networkPairSlippage']>['Arbitrum_Arbitrum'] = {
        ...(networkSlippage?.[key as keyof typeof networkSlippage] ?? {}),
        [network]: newValue,
      };

      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          networkPairSlippage: {
            ...networkSlippage,
            [key]: newNetworkPairSlippage,
          },
        },
      });
    },
  });
}

/**
 *
 */
export async function editTokenMinProfit(
  tokenName: string,
  networkPair: string,
  minRequiredProfit: DBToken['minRequiredProfit'],
) {
  const currentValue = `${((minRequiredProfit ?? {})[networkPair as keyof DBToken['minRequiredProfit']] ?? 0)}`;

  Popup.singleInput({
    title: `Edit ${tokenName} ${networkPair} trade slippage`,
    text: `Enter ${networkPair} trade slippage.`,
    value: currentValue.toString(),
    didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, 'forward'),
    validator: (v) => {
      if (isNaN(parseFloat(v))) return 'Min Profit must be a number';
      if (parseFloat(v).toString() !== v) return 'Min Profit must be a number';
      if (parseFloat(v) < 0) return 'Min Profit must be positive';
      return null;
    },
    onNewValue: (newValue) => {
      const newValueUpd: number = parseFloat(newValue);

      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          minRequiredProfit: {
            ...(minRequiredProfit ?? {}),
            [networkPair as keyof DBToken['minRequiredProfit']]: newValueUpd,
          },
        },
      });
    },
  });
}

export function toggleTokenNetworkPairTrackIgnore(
  tokenName: string,
  networkPairTrackIgnore: DBToken['networkPairTrackIgnore'],
  networkA: NETWORK,
  networkB: NETWORK,
  networkToggle: NETWORK,
) {
  const [n1, n2] = [networkA, networkB].sort((a, b) => a.localeCompare(b)) as [NETWORK, NETWORK];
  const nKey = `${n1}_${n2}` as `${NETWORK}_${NETWORK}`;
  const currentValue = networkPairTrackIgnore?.[nKey]?.[networkToggle] ?? false;

  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkPairTrackIgnore: {
        ...networkPairTrackIgnore,
        [nKey]: {
          ...(networkPairTrackIgnore?.[nKey] ?? {}),
          [networkToggle]: currentValue ? null : true,
        },
      },
    },
  });
}

export function toggleTokenNetworkTrackIgnore(
  tokenName: string,
  networkTrackIgnore: DBToken['networkTrackIgnore'],
  networkToggle: NETWORK,
) {
  const currentValue = networkTrackIgnore?.[networkToggle] ?? false;

  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
    tokenName,
    updates: {
      networkTrackIgnore: {
        ...networkTrackIgnore,
        [networkToggle]: currentValue ? null : true,
      },
    },
  });
}

/**
 *
 */
export async function addTokenBridge(tokenName: string, tokenBridge: DBToken['bridge'], bridges: DBBridges) {
  let selectedBridgeName: string;
  // Prompt to select bridgeName
  {
    const allowedBridgeNames = Object.keys(bridges);
    const existingBridgeNames = Object.keys(tokenBridge ?? {});

    const allowedInputValues = allowedBridgeNames
      .filter((v) => !existingBridgeNames.includes(v))
      .sort((a, b) => a.localeCompare(b));
    const numSuggestionToDisplay = 20;

    const renderSuggestions = (inputValue: string) => {
      const inputValueLower = inputValue.toLowerCase();
      const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
        v.toLowerCase().includes(inputValueLower),
      );

      const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
      const remainingKeys = allowedInputValuesFiltered.slice(
        numSuggestionToDisplay,
        allowedInputValuesFiltered.length,
      );

      const swalContainer = SwalStyled.getHtmlContainer()!;

      swalContainer.innerHTML = `
			<div class="container">
				${suggestionKeys
        .map(
          (name) =>
            `<span
								id="input-suggestion-${name}" 
								class="swalInputSuggestion badge text-dark bg-light m-1"
								style="display: inline-flex; align-items: center; gap: 4px;"
							>
								${name}
							</span>`,
        )
        .join('')}
				<span class="badge text-secondary bg-light">${
        remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
      }</span>
				<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
			</div>
		`;

      SwalStyled.resetValidationMessage();

      for (const name of suggestionKeys) {
        document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
          SwalStyled.getInput()!.value = name;
          SwalStyled.resetValidationMessage();
          renderSuggestions(name);
          SwalStyled.getInput()?.focus();
        });
      }
    };

    const bridgeNameResult = await SwalStyled.fire({
      title: `Add ${tokenName} bridge`,
      input: 'text',
      inputPlaceholder: `Bridge name`,
      html: `<div class="container"></div>`,
      allowEnterKey: true,
      confirmButtonText: 'Select',
      didOpen: () => {
        renderSuggestions('');

        const inputField = SwalStyled.getInput();
        inputField!.oninput = (e) => {
          // @ts-ignore
          const inputValue: string = e.target.value;
          renderSuggestions(inputValue);
        };
      },
      inputValidator: (v) => {
        if (!allowedInputValues.includes(v as NETWORK)) return 'Wrong bridge name. Use suggestions';
        return null;
      },
    });

    if (!bridgeNameResult.isConfirmed) return;
    selectedBridgeName = bridgeNameResult.value;
  }

  let selectedBridgeNetwork: NETWORK;
  // Prompt to select one network
  {
    const allowedNetworkNames = Object.values(NETWORK);
    const existingNetworkNames = Object.values({});

    const allowedInputValues = allowedNetworkNames
      .filter((v) => !existingNetworkNames.includes(v))
      .sort((a, b) => a.localeCompare(b));
    const numSuggestionToDisplay = 20;

    const renderSuggestions = (inputValue: string) => {
      const inputValueLower = inputValue.toLowerCase();
      const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
        v.toLowerCase().includes(inputValueLower),
      );

      const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
      const remainingKeys = allowedInputValuesFiltered.slice(
        numSuggestionToDisplay,
        allowedInputValuesFiltered.length,
      );

      const swalContainer = SwalStyled.getHtmlContainer()!;

      swalContainer.innerHTML = `
			<div class="container">
				${suggestionKeys
        .map(
          (name) =>
            `<span
								id="input-suggestion-${name}" 
								class="swalInputSuggestion badge text-dark bg-light m-1"
								style="display: inline-flex; align-items: center; gap: 4px;"
							>
								<img src="${getNetworkImage(name)}" width="12px" />
								${name}
							</span>`,
        )
        .join('')}
				<span class="badge text-secondary bg-light">${
        remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
      }</span>
				<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
			</div>
		`;

      SwalStyled.resetValidationMessage();

      for (const name of suggestionKeys) {
        document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
          SwalStyled.getInput()!.value = name;
          SwalStyled.resetValidationMessage();
          renderSuggestions(name);
          SwalStyled.getInput()?.focus();
        });
      }
    };

    const bridgeNetworkResult = await SwalStyled.fire({
      title: `Select ${tokenName} ${selectedBridgeName} network`,
      input: 'text',
      inputPlaceholder: `Network name`,
      html: `<div class="container"></div>`,
      allowEnterKey: true,
      confirmButtonText: 'Save',
      didOpen: () => {
        renderSuggestions('');

        const inputField = SwalStyled.getInput();
        inputField!.oninput = (e) => {
          // @ts-ignore
          const inputValue: string = e.target.value;
          renderSuggestions(inputValue);
        };
      },
      inputValidator: (v) => {
        if (!allowedInputValues.includes(v as NETWORK)) return 'Wrong network name. Use suggestions';
        return null;
      },
    });

    if (!bridgeNetworkResult.isConfirmed) return;
    selectedBridgeNetwork = bridgeNetworkResult.value;
  }

  const newTokenBridge = structuredClone(tokenBridge ?? {});
  newTokenBridge[selectedBridgeName] = {
    automation: false,
    priority: 0,
    networks: [selectedBridgeNetwork],
  };

  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { bridge: newTokenBridge } });
}

/**
 *
 */
export async function addTokenBridgeNetwork(
  tokenName: string,
  bridgeName: string,
  tokenBridge: DBToken['bridge'],
) {
  const allowedNetworkNames = Object.values(NETWORK);
  const existingNetworkNames = tokenBridge?.[bridgeName].networks ?? [];

  const allowedInputValues = allowedNetworkNames
    .filter((v) => !existingNetworkNames.includes(v))
    .sort((a, b) => a.localeCompare(b));
  const numSuggestionToDisplay = 20;

  const renderSuggestions = (inputValue: string) => {
    const inputValueLower = inputValue.toLowerCase();
    const allowedInputValuesFiltered = allowedInputValues.filter((v) =>
      v.toLowerCase().includes(inputValueLower),
    );

    const suggestionKeys = allowedInputValuesFiltered.slice(0, numSuggestionToDisplay);
    const remainingKeys = allowedInputValuesFiltered.slice(
      numSuggestionToDisplay,
      allowedInputValuesFiltered.length,
    );

    const swalContainer = SwalStyled.getHtmlContainer()!;

    swalContainer.innerHTML = `
			<div class="container">
				${suggestionKeys
      .map(
        (name) =>
          `<span
								id="input-suggestion-${name}" 
								class="swalInputSuggestion badge text-dark bg-light m-1"
								style="display: inline-flex; align-items: center; gap: 4px;"
							>
								<img src="${getNetworkImage(name)}" width="12px" />
								${name}
							</span>`,
      )
      .join('')}
				<span class="badge text-secondary bg-light">${
      remainingKeys.length > 0 ? `...${remainingKeys.length}` : ''
    }</span>
				<span class="badge text-secondary bg-light">${suggestionKeys.length === 0 ? `No matching entries` : ''}</span>
			</div>
		`;

    SwalStyled.resetValidationMessage();

    for (const name of suggestionKeys) {
      document.getElementById(`input-suggestion-${name}`)?.addEventListener('click', () => {
        SwalStyled.getInput()!.value = name;
        SwalStyled.resetValidationMessage();
        renderSuggestions(name);
        SwalStyled.getInput()?.focus();
      });
    }
  };

  const bridgeNetworkResult = await SwalStyled.fire({
    title: `Add ${tokenName} ${bridgeName} network`,
    input: 'text',
    inputPlaceholder: `Network name`,
    html: `<div class="container"></div>`,
    allowEnterKey: true,
    confirmButtonText: 'Save',
    didOpen: () => {
      renderSuggestions('');

      const inputField = SwalStyled.getInput();
      inputField!.oninput = (e) => {
        // @ts-ignore
        const inputValue: string = e.target.value;
        renderSuggestions(inputValue);
      };
    },
    inputValidator: (v) => {
      if (!allowedInputValues.includes(v as NETWORK)) return 'Wrong network name. Use suggestions';
      return null;
    },
  });

  if (!bridgeNetworkResult.isConfirmed) return;
  const selectedBridgeNetwork: NETWORK = bridgeNetworkResult.value;

  const newTokenBridge = structuredClone(tokenBridge ?? {});
  newTokenBridge[bridgeName] = {
    automation: newTokenBridge[bridgeName]?.automation ?? false,
    priority: newTokenBridge[bridgeName]?.priority ?? 0,
    networks: [...(newTokenBridge[bridgeName]?.networks ?? []), selectedBridgeNetwork].sort((a, b) =>
      a.localeCompare(b),
    ),
  };

  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { bridge: newTokenBridge } });
}

/**
 *
 */
export async function deleteTokenBridgeNetwork(
  tokenName: string,
  bridgeName: string,
  networkName: NETWORK,
  tokenBridge: DBToken['bridge'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} ${bridgeName} ${networkName} network`,
    text: `Are you sure want to delete ${tokenName} ${bridgeName} ${networkName} network?`,
    onConfirm: () => {
      let newTokenBridge = structuredClone(tokenBridge ?? {});

      // Delete networkName from bridgeName
      const newTokenBridgeNameData: NonNullable<DBToken['bridge']>['anyBridge'] = {
        automation: newTokenBridge[bridgeName]?.automation ?? false,
        priority: newTokenBridge[bridgeName]?.priority ?? 0,
        networks: (newTokenBridge[bridgeName]?.networks ?? []).filter(
          (n: NETWORK) => n !== networkName,
        ),
      };
      newTokenBridge[bridgeName] = newTokenBridgeNameData;
      if (newTokenBridgeNameData.networks.length === 0) {
        delete newTokenBridge[bridgeName];
      }

      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { bridge: newTokenBridge } });
    },
  });
}

/**
 *
 */
export async function deleteTokenBridge(
  tokenName: string,
  bridgeName: string,
  tokenBridge: DBToken['bridge'],
) {
  Popup.confirm({
    title: `Delete ${tokenName} ${bridgeName} network`,
    text: `Are you sure want to delete ${tokenName} ${bridgeName} network?`,
    onConfirm: () => {
      const newTokenBridge = structuredClone(tokenBridge ?? {});
      delete newTokenBridge[bridgeName];

      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { bridge: newTokenBridge } });
    },
  });
}

/**
 *
 */
export function updateBridgeAutomation(
  tokenName: string,
  bridgeName: string,
  tokenBridge: DBToken['bridge'],
) {
  const newTokenBridge = structuredClone(tokenBridge ?? {});
  if (newTokenBridge[bridgeName]) {
    newTokenBridge[bridgeName].automation = !newTokenBridge[bridgeName].automation;
  }
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { bridge: newTokenBridge } });
}

/**
 *
 */
export function updateBridgePriority(
  tokenName: string,
  bridgeName: string,
  tokenBridge: DBToken['bridge'],
  priority: 'inc' | 'dec',
) {
  const newTokenBridge = structuredClone(tokenBridge ?? {});
  if (newTokenBridge[bridgeName]) {
    newTokenBridge[bridgeName].priority =
      newTokenBridge[bridgeName].priority + (priority === 'inc' ? 1 : -1);
    if (newTokenBridge[bridgeName].priority < 0) {
      newTokenBridge[bridgeName].priority = 0;
    }
  }
  sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { bridge: newTokenBridge } });
}

/**
 *
 */
export function updateTokenComments(tokenName: string, comments: DBToken['comments']) {
  Popup.singleInput({
    title: `Edit ${tokenName} comment`,
    value: comments,
    validator: (v) => null,
    onNewValue: (newValue) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, { tokenName, updates: { comments: newValue } });
    },
  });
}

export function updateTokenNetworkPairComments(
  tokenName: string,
  networkA: NETWORK,
  networkB: NETWORK,
  currentValue: string,
  networkPairComments: DBToken['networkPairComments'],
) {
  Popup.singleInput({
    title: `Edit ${tokenName} ${networkA}-${networkB} comment`,
    value: currentValue,
    validator: (v) => null,
    onNewValue: (newValue) => {
      sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
        tokenName,
        updates: {
          networkPairComments: {
            ...networkPairComments,
            [`${networkA}_${networkB}`]: newValue.length > 0 ? newValue : null,
          },
        },
      });
    },
  });
}

/**
 *
 */
export function deleteToken(tokenName: string) {
  Popup.confirm({
    title: `Delete ${tokenName}`,
    text: `Are you sure want to delete token ${tokenName}?`,
    onConfirm: () => {
      sendUpdateEvent(MODULE_EVENTS.DELETE_TOKEN, { tokenName });
    },
  });
}
