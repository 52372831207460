import Apeswap from "./Apeswap.png"
import Pancakeswap from "./Pancakeswap.png"
import Shibaswap from "./Shibaswap.png"
import Sushiswap from "./Sushiswap.png"
import Uniswap from "./Uniswap.png"
import Aerodrome from "./Aerodrome.png"
import Alienbase from "./Alienbase.png"
import Baseswap from "./Baseswap.png"

import Jupiter from "./Jupiter.png"
import Zerox from "./zerox.png"
import Biswap from "./Biswap.png"
import TraderJoe from "./TraderJoe.png"
import Thena from "./Thena.png"
import Sdex from "./Sdex.png"
import Balancer from "./Balancer.png"
import Pharaoh from "./Pharaoh.png"
import Velodrome from "./Velodrome.png"
import Life from "./Life.png"
import Retro from "./Retro.png"
import Frax from "./Frax.png"
import Dfyn from "./Dfyn.png"
import Elk from "./Elk.png"
import Cometh from "./Cometh.png"
import Jetswap from "./Jetswap.png"
import Radioshack from "./Radioshack.png"
import Dystopia from "./Dystopia.png"
import Dino from "./Dino.png"
import Dackieswap from "./Dackieswap.png"
import Meshswap from "./Meshswap.png"

import Ampleswap from "./Ampleswap.png"
import Babydoge from "./Babydoge.png"
import Babyswap from "./Babyswap.png"
import Magicswap from "./Magicswap.png"
import Pangolin from "./Pangolin.png"
import Ramses from "./Ramses.png"
import Squadswap from "./Squadswap.png"
import Verse from "./Verse.png"

import Camelot from "./Camelot.png"

import Quickswap from "./Quickswap.png"

import Bakeryswap from "./Bakeryswap.png"
import Cafeswap from "./Cafeswap.png"

import Default from "./Default.png"

const DEX_IMAGES: {
	[dexName: string]: string
	Default: string
} = {
	uni: Uniswap,
	quick: Quickswap,
	pancake: Pancakeswap,
	sushi: Sushiswap,
	camelot: Camelot,
	joe: TraderJoe,
	aero: Aerodrome,
	velo: Velodrome,
	alienbase: Alienbase,
	baseswap: Baseswap,
	ape: Apeswap,
	thena: Thena,
	sdex: Sdex,
	_jupiter: Jupiter,
	_zerox: Zerox,

	balancer: Balancer,
	pharaoh: Pharaoh,
	life: Life,
	retro: Retro,
	frax: Frax,
	dfyn: Dfyn,
	elk: Elk,
	cometh: Cometh,
	jetswap: Jetswap,
	radioshack: Radioshack,
	dystopia: Dystopia,
	dino: Dino,
	shiba: Shibaswap,
	verse: Verse,
	babydoge: Babydoge,
	babyswap: Babyswap,
	biswap: Biswap,
	squadswap: Squadswap,
	ampleswap: Ampleswap,
	ramses: Ramses,
	magicswap: Magicswap,
	pangolin: Pangolin,
	dackieswap: Dackieswap,
	meshswap: Meshswap,

	// Ethereum
	Apeswap,
	Pancakeswap,
	Shibaswap,
	Sushiswap,
	Uniswap,

	// Binance
	Biswap,
	TraderJoe,

	// Arbitrum
	Camelot,

	// Polygon
	Quickswap,

	// Old
	Bakeryswap,
	Cafeswap,

	Default: Default,
}

export function getDexImage(dexName: string, dexVersion?: string) {
	return DEX_IMAGES[dexName] || (dexVersion && DEX_IMAGES[dexVersion]) || DEX_IMAGES.Default
}
