import {memo, useEffect, useState} from "react"

import {Button, Col, Container, Row, Stack} from "react-bootstrap"

import AceEditor from "react-ace"
import "ace-builds/src-min-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/snippets/python"
import "ace-builds/src-noconflict/theme-monokai"

import Header_C, {
    HeaderBlockNumSection_C,
    HeaderConnectedServicesSection_C,
    HeaderSection,
    HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, {ContentSection, ContentSpacer} from "../../../components/Content"
import Sidebar, {SidebarRootNavLinks, SidebarSection} from "../../../components/Sidebar"

import {AppState, sendUpdateEvent} from "../../../App"
import {SidebarSettingsNavLinks} from ".."

import {Updater} from "use-immer"
import {fetchTxsConf, updateTxsConf} from "./actions"
import {MODULE_EVENTS} from "../../../common"

/**
 * Configs settings page
 *  Route '/settings/configs'
 */
type SettingsConfigsProps = {
    configs?: AppState["configs"]
    workersStarted: boolean
    setAppState: Updater<AppState>
}
export default memo(function SettingsConfigs({configs, workersStarted, setAppState}: SettingsConfigsProps) {
    const [currentConfName, setCurrentConfName] = useState("transactions")

    useEffect(() => {
        fetchTxsConf()
        setTimeout(() => {
            if (!configs || !(configs as any)[currentConfName]) {
                fetchTxsConf()
            }
        }, 2000)
    }, [])

    return (
        <>
            <Header_C>
                <HeaderSection>
                    <HeaderStartStopSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="center">
                    <HeaderConnectedServicesSection_C/>
                </HeaderSection>
                <HeaderSection justifyContent="end">
                    <HeaderBlockNumSection_C/>
                </HeaderSection>
            </Header_C>

            <Container fluid className="main">
                <Row>
                    <Col className="sidebarContainer">
                        <Sidebar>
                            <SidebarRootNavLinks/>
                            <SidebarSettingsNavLinks/>

                            <SidebarSection renderP gap={1}>
                                <SideButtons
                                    confNames={["transactions"]}
                                    currentConfName={currentConfName}
                                    setCurrentConfName={setCurrentConfName}
                                />
                            </SidebarSection>
                        </Sidebar>
                    </Col>
                    <Col className="contentContainer">
                        <Content>
                            <ContentSection>
                                {!!configs && !!(configs as any)[currentConfName] && (
                                    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                        <div style={{width: "60%", marginRight: 24}}>
                                            <AceEditor
                                                mode="python"
                                                theme="monokai"
                                                value={(configs as any)[currentConfName]}
                                                style={{
                                                    width: "100%",
                                                    height: "80vh",
                                                }}
                                                onChange={(val) => {
                                                    const currentConf = val
                                                    setAppState((draft) => {
                                                        ;(draft.configs as any)[currentConfName] = currentConf
                                                    })
                                                }}
                                                name="conf-editor"
                                                editorProps={{$blockScrolling: true}}
                                            />
                                            <Button
                                                size="sm"
                                                variant="success"
                                                style={{
                                                    width: 200,
                                                    marginTop: 24,
                                                }}
                                                onClick={() => updateTxsConf((configs as any)[currentConfName]).catch(
                                                    (e) =>
                                                        sendUpdateEvent(
                                                            MODULE_EVENTS.NOTIFICATION_TO_CLIENT,
                                                            {
                                                                type: "error",
                                                                msg: "Failed to parse config, please check syntax",
                                                                autoClose: 5000,
                                                            }
                                                        )
                                                )}
                                            >
                                                Update Config
                                            </Button>
                                        </div>
                                        <div style={{width: "40%", fontSize: 14}}>
                                            <h5>Description for some of the config fields</h5>
                                            <br/>
                                            <p>
                                                <b>merkle</b>, <b>blocknative</b>, <b>bloxroute</b> - enable
                                                and configure provider (only one can be enabled at a time).
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>merkle.revert</b> - sets <i>prevent_reverts</i> to{" "}
                                                <i>true</i> for merkle rpc calls.
                                            </p>
                                            <br/>

                                            <p>
                                                <b>autosell</b>, <b>autoclaim</b> - enable and configure
                                                autoclaim and autosell logic.
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>autosell.approveInAdvanceEnabled</b> - sends
                                                approve tx on the opposit trade network after bridge tx is
                                                sent in order to reduce trade time.
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>autosell.tradeTTL</b>, <b>autoclaim.ttl</b> - how
                                                long should we wait for tokens to be transfered before
                                                rejecting autosell or autoclaim flow.
                                            </p>
                                            <br/>

                                            <p>
                                                <b>mempool</b> - mempool management fields.
                                            </p>
                                            <br/>

                                            <p>
                                                <b>gasRules</b> - gas fee management fields for all networks.
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>polygonSwapRulesEnabled</b> - enables spefic gas
                                                rules for Polygon (rules are defined in the <b>polygonSwap</b>{" "}
                                                fields).
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>polygonBridgeRulesEnabled</b> - enables spefic
                                                bridge rules for Polygon (rules are defined in the{" "}
                                                <b>polygonBridge</b> fields).
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>graduallyUpgradeSwapTxGas</b> - upgrade swap txs
                                                gas price after some time it was sent to avoid .0000001
                                                overtake issue.
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>ethSwap</b> - swap rules specific for Ethereum txs
                                                (defines gas price depending on profit).
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>swap</b> - swap rules specific for other network
                                                txs (defines gas price depending on profit).
                                            </p>
                                            <p style={{marginLeft: 24}}>
                                                &#x2022; <b>networks</b> - main gas rules (are overriden by
                                                rules defined above).
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </ContentSection>
                            <ContentSection>
                                <ContentSpacer height={100}/>
                            </ContentSection>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </>
    )
})

/**
 *
 */
type SideButtonsProps = {
    confNames: string[]
    currentConfName: string
    setCurrentConfName: any
}
const SideButtons = memo(function SideButtons({
                                                  confNames,
                                                  currentConfName,
                                                  setCurrentConfName,
                                              }: SideButtonsProps) {
    return (
        <Stack direction="vertical" gap={3}>
            {confNames.map((confName) => (
                <Button
                    key={confName}
                    size="sm"
                    variant="success"
                    onClick={() => setCurrentConfName(confName)}
                >
                    {confName.charAt(0).toUpperCase() + confName.slice(1)}
                </Button>
            ))}
        </Stack>
    )
})
