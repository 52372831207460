import { BuildApprove, BuildBridge, BuildDex, BuildTransfer, LockedNonces, Trade, TxType } from '../types';
import { NETWORK } from '../defaults';

/**
 *
 */
export enum TRANSACTIONS_EVENTS {
  TRADE_CREATED = 'TRADE_CREATED',
  TRANSACTIONS_SENT = 'TRANSACTIONS_SENT',
  TRANSACTIONS_CREATED = 'TRANSACTIONS_CREATED',
  TRANSACTIONS_OVERTAKEN = 'TRANSACTIONS_OVERTAKEN',
  TRANSACTIONS_CONFIRMED = 'TRANSACTIONS_CONFIRMED',
  TRADE_COMPLETED = 'TRADE_COMPLETED',
  TRANSACTIONS_LIST_ARCHIVED = 'TRANSACTIONS_LIST_ARCHIVED',
  TRANSACTIONS_LIST_ARCHIVED_RESPONSE = 'TRANSACTIONS_LIST_ARCHIVED_RESPONSE',
  TRANSACTIONS_LIST_ARCHIVED_ITEM = 'TRANSACTIONS_LIST_ARCHIVED_ITEM',
  TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE = 'TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE',
  TRANSACTIONS_CREATE_TRANSFER_TX = 'TRANSACTIONS_CREATE_TRANSFER_TX',
  TRANSACTIONS_CREATE_DEX_TX = 'TRANSACTIONS_CREATE_DEX_TX',
  TRANSACTIONS_CREATE_DEX_APPROVAL_TX = 'TRANSACTIONS_CREATE_DEX_APPROVAL_TX',
  TRANSACTIONS_CREATE_BRIDGE_TX = 'TRANSACTIONS_CREATE_BRIDGE_TX',
  TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX = 'TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX',
  TRANSACTIONS_UPDATE_SELL_MIN_AMOUNT_OUT = 'TRANSACTIONS_UPDATE_SELL_MIN_AMOUNT_OUT',
  TRANSACTIONS_UPDATE_SELL_BRIBE = 'TRANSACTIONS_UPDATE_SELL_BRIBE',
  PIN_TOKEN = 'PIN_TOKEN',
  PIN_TOKEN_NO_TRADE = 'PIN_TOKEN_NO_TRADE',
  UPDATE_LOCKED_NONCES = 'UPDATE_LOCKED_NONCES',
  RETRY_CLAIM = 'RETRY_CLAIM',
  RETRY_SELL = 'RETRY_SELL',
  UPDATE_SELL_HASH = 'UPDATE_SELL_HASH',
  UPDATE_SELL_GAS_PRICE = 'UPDATE_SELL_GAS_PRICE',
}

/**
 *
 */
export type TRANSACTIONS_EVENTS_PARAMS = {
  [TRANSACTIONS_EVENTS.TRADE_CREATED]: Partial<Trade>
  [TRANSACTIONS_EVENTS.TRANSACTIONS_SENT]: Partial<{ tokenName: string, txType: TxType }[]>
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATED]: Partial<Trade>
  [TRANSACTIONS_EVENTS.TRANSACTIONS_OVERTAKEN]: Partial<Trade>
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CONFIRMED]: Partial<Trade>
  [TRANSACTIONS_EVENTS.TRADE_COMPLETED]: Partial<Trade>
  [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED]: string
  [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_RESPONSE]: string[]
  [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM]: string
  [TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE]: { fileName: string; data: string }
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_TRANSFER_TX]: BuildTransfer
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_DEX_TX]: BuildDex
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_DEX_APPROVAL_TX]: BuildApprove
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_BRIDGE_TX]: BuildBridge
  [TRANSACTIONS_EVENTS.TRANSACTIONS_CREATE_BRIDGE_APPROVAL_TX]: BuildApprove
  [TRANSACTIONS_EVENTS.PIN_TOKEN]: {
    tokenName: string
    pinData: {
      networkFrom: NETWORK
      networkTo: NETWORK
      pinnedAt: number
    }
  }
  [TRANSACTIONS_EVENTS.PIN_TOKEN_NO_TRADE]: {
    tokenName: string
    pinData: {
      networkFrom: NETWORK
      networkTo: NETWORK
      pinnedAt: number
    }
  }
  [TRANSACTIONS_EVENTS.UPDATE_LOCKED_NONCES]: LockedNonces
  [TRANSACTIONS_EVENTS.RETRY_CLAIM]: {
    tradeId: string,
    public?: boolean
  }
  [TRANSACTIONS_EVENTS.RETRY_SELL]: {
    tradeId: string
  }
  [TRANSACTIONS_EVENTS.UPDATE_SELL_HASH]: {
    tradeId: string
    hash: string
  }
  [TRANSACTIONS_EVENTS.UPDATE_SELL_GAS_PRICE]: {
    tradeId: string;
    gasPriceGwei: number;
  }
  [TRANSACTIONS_EVENTS.TRANSACTIONS_UPDATE_SELL_MIN_AMOUNT_OUT]: {
    tradeId: string,
    minAmountOut: number
  }
  [TRANSACTIONS_EVENTS.TRANSACTIONS_UPDATE_SELL_BRIBE]: {
    tradeId: string,
    bribe: number
  }
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in TRANSACTIONS_EVENTS]: TRANSACTIONS_EVENTS_PARAMS[Property]
}
