import { memo, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';

import Header_C, {
  HeaderBlockNumSection_C,
  HeaderConnectedServicesSection_C,
  HeaderSection,
  HeaderStartStopSection_C,
} from '../../components/Header';
import Content from '../../components/Content';
import Sidebar, {
  SidebarFilterButton,
  SidebarFilterRow,
  SidebarLink,
  SidebarRootNavLinks,
  SidebarSection,
} from '../../components/Sidebar';
import { SwalStyled } from '../../helper/Popup';
import { sendUpdateEvent } from '../../App';
import { MODULE_EVENTS } from '../../common';
import { createRoot, Root } from 'react-dom/client';

/**
 * Filter data
 */
export type TransactionsFilterData = {
  success: 'true' | 'false' | 'none'
  failed: 'true' | 'false' | 'none'
  unknown: 'true' | 'false' | 'none'
  replaced: 'true' | 'false' | 'none'
  manual: 'true' | 'false' | 'none'
}
export const defaultFilterData: TransactionsFilterData = {
  success: 'none',
  failed: 'none',
  unknown: 'none',
  replaced: 'none',
  manual: 'none',
};

/**
 * Transactions navigation links component
 */
export function SidebarTransactionsNavLinks() {
  return (
    <SidebarSection name="Transactions navigation">
      <Stack gap={2}>
        <SidebarLink icon="transactions" text="Latest Transactions" href="/tx/latest" />
        <SidebarLink icon="archive" text="Archived Transactions" href="/tx/archived" />
      </Stack>
    </SidebarSection>
  );
}

/**
 * Transactions page
 *  Route '/tx'
 */
export default memo(function Transactions() {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Redirect user to specific setting page
   */
  useEffect(() => {
    const { pathname } = location;

    if (pathname.replaceAll('/', '') === 'tx') {
      navigate('/tx/latest');
    }
  }, [location.pathname]);

  /**
   * Render is here only to keep the sidebar with some items
   *  so that the page is not blank while user is being redirected
   */
  return (
    <>
      <Header_C>
        <HeaderSection>
          <HeaderStartStopSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="center">
          <HeaderConnectedServicesSection_C />
        </HeaderSection>
        <HeaderSection justifyContent="end">
          <HeaderBlockNumSection_C />
        </HeaderSection>
      </Header_C>

      <Container fluid className="main">
        <Row>
          <Col className="sidebarContainer">
            <Sidebar>
              <SidebarRootNavLinks />
              <SidebarTransactionsNavLinks />
            </Sidebar>
          </Col>
          <Col className="contentContainer">
            <Content>
              <Content>{/* should be empty  */}</Content>
            </Content>
          </Col>
        </Row>
      </Container>
    </>
  );
});

/**
 *
 */
export const SideButtons = memo(function SideButtons() {
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button style={{ marginBottom: '8px' }} size="sm" variant="secondary" onClick={() => navigate('/tx/new')}>
        Create new transaction
      </Button>
      <Button style={{ marginBottom: '8px' }} size="sm" variant="secondary"
              onClick={() => handleRetrySellOrClaimClick(false)}>
        Retry Sell
      </Button>
      <Button style={{ marginBottom: '8px' }} size="sm" variant="secondary"
              onClick={() => handleRetrySellOrClaimClick(true)}>
        Retry Claim
      </Button>
    </div>
  );
});

export const handleRetrySellOrClaimClick = async (claim: boolean, initValue?: string) => {
  SwalStyled.fire({
    title: `Retry ${claim ? 'Claim' : 'Sell'}`,
    html: '<div id="retry-claim-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '400px',
    inputValue: initValue ?? '',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#retry-claim-form',
      ) as HTMLElement;

      if (formContainer) {
        // Create a root.
        const root: Root = createRoot(formContainer);

        // Render the React component inside the Swal modal
        root.render(
          <RetrySellOrClaimForm
            claim={claim}
            initialTradeId={initValue}
            onClose={() => SwalStyled.close()}
          />,
        );

        // Store the root instance to unmount later
        (SwalStyled as any)._retryClaimRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#retry-claim-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._retryClaimRoot) {
        const root: Root = (SwalStyled as any)._retryClaimRoot;
        root.unmount();
        delete (SwalStyled as any)._retryClaimRoot;
      }
    },
  });
};

interface RetrySellOrClaimFormProps {
  claim: boolean;
  initialTradeId?: string;
  onClose: () => void;
}

const RetrySellOrClaimForm: React.FC<RetrySellOrClaimFormProps> = ({ claim, initialTradeId, onClose }) => {
  const [tradeId, setTradeId] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setTradeId(initialTradeId ?? tradeId ?? '');
  }, [initialTradeId]);

  const handleRetry = () => {
    const trimmedTradeId = tradeId.trim();
    const tradeIdNumber = Number(trimmedTradeId);

    // Validation: Trade ID must be a number and >= 1729105791000
    if (
      !trimmedTradeId ||
      isNaN(tradeIdNumber) ||
      tradeIdNumber < 1729105791000
    ) {
      setError('Wrong Trade ID');
      return;
    }

    // Send the update event
    const event = claim
      ? MODULE_EVENTS.RETRY_CLAIM
      : MODULE_EVENTS.RETRY_SELL;
    sendUpdateEvent(event, {
      tradeId: trimmedTradeId,
      public: isPublic,
    });

    // Close the modal
    onClose();
  };

  return (
    <Form>
      <Form.Group controlId="tradeId" style={{ marginBottom: '12px' }}>
        <Form.Control
          type="text"
          placeholder="Enter Trade ID"
          value={tradeId}
          onChange={(e) => {
            setTradeId(e.target.value);
            if (error) setError('');
          }}
          isInvalid={!!error}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>

      {claim && (
        <Form.Group controlId="publicToggle" style={{
          maxWidth: 200,
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'end',
          marginBottom: '36px',
        }}>
          <Form.Check
            type="checkbox"
            label="Public (will skip sell)"
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
          />
        </Form.Group>
      )}

      <Button variant="primary" onClick={handleRetry}>
        Retry
      </Button>
    </Form>
  );
};

export const handleUpdateSellHash = async (tradeId: string) => {
  SwalStyled.fire({
    title: `Update Bridge Tx Hash`,
    html: '<div id="update-bridge-hash-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '400px',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#update-bridge-hash-form',
      ) as HTMLElement;

      if (formContainer) {
        // Create a root.
        const root: Root = createRoot(formContainer);

        // Render the React component inside the Swal modal
        root.render(
          <UpdateSellHashForm
            tradeId={tradeId}
            onClose={() => SwalStyled.close()}
          />,
        );

        // Store the root instance to unmount later
        (SwalStyled as any)._updateSellHashRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#update-sell-hash-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._updateSellHashRoot) {
        const root: Root = (SwalStyled as any)._updateSellHashRoot;
        root.unmount();
        delete (SwalStyled as any)._updateSellHashRoot;
      }
    },
  });
};

interface UpdateSellHashFormProps {
  tradeId: string;
  onClose: () => void;
}

const UpdateSellHashForm: React.FC<UpdateSellHashFormProps> = ({ tradeId, onClose }) => {
  const [hash, setHash] = useState('');

  const handleUpdate = () => {
    sendUpdateEvent(MODULE_EVENTS.UPDATE_SELL_HASH, { tradeId, hash });
    onClose();
  };

  return (
    <Form>
      <Form.Group controlId="bridge-hash" style={{ marginBottom: '12px' }}>
        <Form.Control
          type="text"
          placeholder="Enter Bridge Tx Hash"
          value={hash}
          onChange={(e) => {
            setHash(e.target.value);
          }}
        />
      </Form.Group>

      <Button variant="primary" onClick={handleUpdate}>
        Update
      </Button>
    </Form>
  );
};

/**
 *
 */
type SidebarTextFilterProps = {
  textFilter: string
  setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
export const SidebarTextFilter = memo(function SidebarTextFilter({
                                                                   textFilter,
                                                                   setTextFilter,
                                                                 }: SidebarTextFilterProps) {
  return (
    <Form.Control
      size="sm"
      type="text"
      placeholder="trade | hash | token | dex | bridge"
      value={textFilter}
      onChange={(e) => setTextFilter(e.target.value)}
    />
  );
});

/**
 *
 */
type SidebarPropertyFilterProps = {
  filterData: TransactionsFilterData
  setFilterData: React.Dispatch<React.SetStateAction<TransactionsFilterData>>
}
export const SidebarPropertyFilter = memo(function SidebarPropertyFilter({
                                                                           filterData,
                                                                           setFilterData,
                                                                         }: SidebarPropertyFilterProps) {
  const { success, failed, replaced, unknown, manual } = filterData;

  return (
    <>
      <SidebarFilterRow text="Success">
        <SidebarFilterButton
          text="True"
          active={success === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              success: success === 'true' ? 'none' : 'true',
            })
          }
        />
        <SidebarFilterButton
          text="False"
          active={success === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              success: success === 'false' ? 'none' : 'false',
            })
          }
        />
      </SidebarFilterRow>
      <SidebarFilterRow text="Failed">
        <SidebarFilterButton
          text="True"
          active={failed === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              failed: failed === 'true' ? 'none' : 'true',
            })
          }
        />
        <SidebarFilterButton
          text="False"
          active={failed === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              failed: failed === 'false' ? 'none' : 'false',
            })
          }
        />
      </SidebarFilterRow>
      <SidebarFilterRow text="Replaced">
        <SidebarFilterButton
          text="True"
          active={replaced === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              replaced: replaced === 'true' ? 'none' : 'true',
            })
          }
        />
        <SidebarFilterButton
          text="False"
          active={replaced === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              replaced: replaced === 'false' ? 'none' : 'false',
            })
          }
        />
      </SidebarFilterRow>
      <SidebarFilterRow text="Unknown">
        <SidebarFilterButton
          text="True"
          active={unknown === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              unknown: unknown === 'true' ? 'none' : 'true',
            })
          }
        />
        <SidebarFilterButton
          text="False"
          active={unknown === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              unknown: unknown === 'false' ? 'none' : 'false',
            })
          }
        />
      </SidebarFilterRow>
      <SidebarFilterRow text="Manual">
        <SidebarFilterButton
          text="True"
          active={manual === 'true'}
          onClick={() =>
            setFilterData({
              ...filterData,
              manual: manual === 'true' ? 'none' : 'true',
            })
          }
        />
        <SidebarFilterButton
          text="False"
          active={manual === 'false'}
          onClick={() =>
            setFilterData({
              ...filterData,
              manual: manual === 'false' ? 'none' : 'false',
            })
          }
        />
      </SidebarFilterRow>
    </>
  );
});

/**
 *
 */
type SidebarClearFiltersProps = {
  textFilter: string
  filterData: TransactionsFilterData
  setTextFilter: React.Dispatch<React.SetStateAction<string>>
  setFilterData: React.Dispatch<React.SetStateAction<TransactionsFilterData>>
}
export const SidebarClearFilters = memo(function SidebarClearFilters({
                                                                       textFilter,
                                                                       filterData,
                                                                       setTextFilter,
                                                                       setFilterData,
                                                                     }: SidebarClearFiltersProps) {
  const filtersPresent =
    false || textFilter !== '' || JSON.stringify(filterData) !== JSON.stringify(defaultFilterData);

  if (!filtersPresent) return <></>;

  return (
    <Button
      size="sm"
      variant="dark"
      onClick={() => {
        setTextFilter('');
        setFilterData(defaultFilterData);
      }}
    >
      Clear all filters
    </Button>
  );
});
