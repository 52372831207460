import {
    AWSGroupScheme,
    DBAutomationRules,
    DBBridge,
    DBMiscSettings,
    DBToken,
    RoutesData,
    SellEvent,
    WorkerIterationResult,
} from "../types"
import {TxsConf} from "../types/transactionConfigTypes"

/**
 *
 */
export enum INTERFACE_EVENTS {
    CLIENT_START_WORKERS = "CLIENT_START_WORKERS", // user presses START button
    CLIENT_STOP_WORKERS = "CLIENT_STOP_WORKERS", // user presses STOP button

    CLIENT_START_ROUTE_SEARCH = "CLIENT_START_ROUTE_SEARCH",

    COMMAND = "COMMAND",

    SYNC_DB = "SYNC_DB", // sync db with server
    RESTART_SERVICE = "RESTART_SERVICE", // restart all microservices of type
    RESTART_AWS_FORMATION = "RESTART_AWS_FORMATION", // sync aws config

    UPDATE_MISC_SETTINGS = "UPDATE_MISC_SETTINGS",
    AWS_GROUPS_UPDATE = "AWS_GROUPS_UPDATE",

    UNPIN_TOKEN = "UNPIN_TOKEN",
    UNPIN_TOKEN_NO_TRADE = "UNPIN_TOKEN_NO_TRADE",

    PIN_TOKEN_AUTOSELL = "PIN_TOKEN_AUTOSELL",
    UNPIN_TOKEN_AUTOSELL = "UNPIN_TOKEN_AUTOSELL",
    DELETE_TOKEN_AUTOSELL = "DELETE_TOKEN_AUTOSELL",
    FORCE_SELL = "FORCE_SELL",

    CREATE_TOKEN = "CREATE_TOKEN",
    DELETE_TOKEN = "DELETE_TOKEN",
    UPDATE_TOKEN = "UPDATE_TOKEN",

    CREATE_BRIDGE = "CREATE_BRIDGE",
    DELETE_BRIDGE = "DELETE_BRIDGE",
    UPDATE_BRIDGE = "UPDATE_BRIDGE",

    UPDATE_AUTOMATION_RULES = "UPDATE_AUTOMATION_RULES",

    // CREATE_AUTOMATION_MIN_COEFF_RULE = "CREATE_AUTOMATION_MIN_COEFF_RULE",
    // DELETE_AUTOMATION_MIN_COEFF_RULE = "DELETE_AUTOMATION_MIN_COEFF_RULE",
    // UPDATE_AUTOMATION_MIN_COEFF_RULE = "UPDATE_AUTOMATION_MIN_COEFF_RULE",

    // CREATE_AUTOMATION_TOKEN_BRIDGE_RULE = "CREATE_AUTOMATION_TOKEN_BRIDGE_RULE",
    // DELETE_AUTOMATION_TOKEN_BRIDGE_RULE = "DELETE_AUTOMATION_TOKEN_BRIDGE_RULE",
    // UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE = "UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE",

    TOGGLE_BRIDGE_AUTOMATION = "TOGGLE_BRIDGE_AUTOMATION",

    CLEAR_CACHED_RESULTS = "CLEAR_CACHED_RESULTS",

    DELETE_TOKEN_KNOWN_ROUTES = "DELETE_TOKEN_KNOWN_ROUTES",

    FETCH_TXS_CONFIG = "FETCH_TXS_CONFIG",
    FETCH_TXS_CONFIG_RESULT = "FETCH_TXS_CONFIG_RESULT",
    UPDATE_TXS_CONFIG = "UPDATE_TXS_CONFIG",
    FORCE_TRIGGER_TX = "FORCE_TRIGGER_TX",

    LIST_JSON_CONFIGS = "LIST_JSON_CONFIGS",
    LIST_JSON_CONFIGS_RESULT = "LIST_JSON_CONFIGS_RESULT",
    REQUEST_JSON_CONFIG = "REQUEST_JSON_CONFIG",
    REQUEST_JSON_CONFIG_RESULT = "REQUEST_JSON_CONFIG_RESULT",
    UPDATE_JSON_CONFIG = "UPDATE_JSON_CONFIG",
    UPDATE_JSON_CONFIG_RESULT = "UPDATE_JSON_CONFIG_RESULT"
}

/**
 *
 */
export type INTERFACE_EVENTS_PARAMS = {
    [INTERFACE_EVENTS.CLIENT_START_WORKERS]: void
    [INTERFACE_EVENTS.CLIENT_STOP_WORKERS]: void

    [INTERFACE_EVENTS.CLIENT_START_ROUTE_SEARCH]: void

    [INTERFACE_EVENTS.COMMAND]: {
        to: string
        cmd: string
    }

    [INTERFACE_EVENTS.SYNC_DB]: void
    [INTERFACE_EVENTS.RESTART_SERVICE]: {
        serviceName: string
    }
    [INTERFACE_EVENTS.RESTART_AWS_FORMATION]: void

    [INTERFACE_EVENTS.UPDATE_MISC_SETTINGS]: {
        updates: Partial<DBMiscSettings>
    }

    [INTERFACE_EVENTS.AWS_GROUPS_UPDATE]: {
        awsGroups: AWSGroupScheme[]
    }

    [INTERFACE_EVENTS.UNPIN_TOKEN]: {
        tokenName: string
    }
    [INTERFACE_EVENTS.UNPIN_TOKEN_NO_TRADE]: {
        tokenName: string
    }

    [INTERFACE_EVENTS.PIN_TOKEN_AUTOSELL]: SellEvent
    [INTERFACE_EVENTS.UNPIN_TOKEN_AUTOSELL]: SellEvent
    [INTERFACE_EVENTS.DELETE_TOKEN_AUTOSELL]: SellEvent
    [INTERFACE_EVENTS.FORCE_SELL]: string // key FromNetwork_ToNetwork_TokenName

    [INTERFACE_EVENTS.CREATE_TOKEN]: {
        tokenName: string
    }
    [INTERFACE_EVENTS.DELETE_TOKEN]: {
        tokenName: string
    }
    [INTERFACE_EVENTS.UPDATE_TOKEN]: {
        tokenName: string
        updates: Partial<DBToken>
    }

    [INTERFACE_EVENTS.CREATE_BRIDGE]: {
        bridgeName: string
    }
    [INTERFACE_EVENTS.DELETE_BRIDGE]: {
        bridgeName: string
    }
    [INTERFACE_EVENTS.UPDATE_BRIDGE]: {
        bridgeName: string
        updates: Partial<DBBridge>
    }

    [INTERFACE_EVENTS.UPDATE_AUTOMATION_RULES]: {
        updates: Partial<DBAutomationRules>
    }

    // [INTERFACE_EVENTS.CREATE_AUTOMATION_MIN_COEFF_RULE]: {
    // 	gasPrice: number
    // }
    // [INTERFACE_EVENTS.DELETE_AUTOMATION_MIN_COEFF_RULE]: {
    // 	gasPrice: number
    // }
    // [INTERFACE_EVENTS.UPDATE_AUTOMATION_MIN_COEFF_RULE]: {
    // 	gasPrice: number
    // 	updates: Partial<DBAutomationMinCoeffRule>
    // }

    // [INTERFACE_EVENTS.CREATE_AUTOMATION_TOKEN_BRIDGE_RULE]: {
    // 	type: string
    // 	name: string
    // }
    // [INTERFACE_EVENTS.DELETE_AUTOMATION_TOKEN_BRIDGE_RULE]: {
    // 	type: string
    // 	name: string
    // }
    // [INTERFACE_EVENTS.UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE]: {
    // 	type: string
    // 	name: string
    // 	updates: Partial<DBAutomationRule> | null
    // }

    [INTERFACE_EVENTS.TOGGLE_BRIDGE_AUTOMATION]: {
        bridgeName: string
        newValue: boolean
    }

    [INTERFACE_EVENTS.CLEAR_CACHED_RESULTS]: {}

    [INTERFACE_EVENTS.DELETE_TOKEN_KNOWN_ROUTES]: {
        tokenName: string
        knownRoutes: RoutesData
    }

    [INTERFACE_EVENTS.FETCH_TXS_CONFIG]: {}
    [INTERFACE_EVENTS.FETCH_TXS_CONFIG_RESULT]: TxsConf
    [INTERFACE_EVENTS.UPDATE_TXS_CONFIG]: TxsConf
    [INTERFACE_EVENTS.FORCE_TRIGGER_TX]: WorkerIterationResult

    [INTERFACE_EVENTS.LIST_JSON_CONFIGS]: {}
    [INTERFACE_EVENTS.LIST_JSON_CONFIGS_RESULT]: { configNames: string[] }
    [INTERFACE_EVENTS.REQUEST_JSON_CONFIG]: { configName: string }
    [INTERFACE_EVENTS.REQUEST_JSON_CONFIG_RESULT]: { configName: string, configData: string }
    [INTERFACE_EVENTS.UPDATE_JSON_CONFIG]: { configName: string, configData: string }
    [INTERFACE_EVENTS.UPDATE_JSON_CONFIG_RESULT]: { configName: string, configData: string }
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
    [Property in INTERFACE_EVENTS]: INTERFACE_EVENTS_PARAMS[Property]
}
