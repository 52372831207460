import { Updater } from 'use-immer';
import { AppState } from '../App';
import { useEffect } from 'react';
import * as yaml from 'js-yaml';

import {
  BRAIN_EVENTS,
  GO_NODER_EVENTS,
  INTERFACE_EVENTS,
  MODULE_EVENTS,
  MODULE_EVENTS_PARAMS,
  NODER_EVENTS,
  TRANSACTIONS_EVENTS,
  WORKER_EVENTS,
} from '../common';
import InnerEmitter from '../common/InnerEmitter';
import Sound from './Sound';
import { MONITORING_EVENTS } from '../common/moduleEvents/monitoringEvents';
import { addToast, errorToast, infoToast, warnToast } from './Toast';

export function useSetEventHandlers(setAppState: Updater<AppState>, toastDataUpdate: (text: string) => void) {
  useEffect(() => {
    const _onInitInterface = onInitInterface.bind(null, setAppState);
    const _onInterfaceEventCache = onInterfaceEventCache.bind(null, setAppState);
    const _onBrainStateUpdate = onBrainStateUpdate.bind(null, setAppState);
    const _onAWSGroupsUpdateProcessed = onAWSGroupsUpdateProcessed.bind(null, setAppState);
    const _onConnectedUsers = onConnectedUsers.bind(null, setAppState);
    const _onConnectedServices = onConnectedServices.bind(null, setAppState);
    const _onUpdateMiscSettingsProcessed = onUpdateMiscSettingsProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.INIT_INTERFACE, _onInitInterface);
    InnerEmitter.on(MODULE_EVENTS.INTERFACE_EVENT_CACHE, _onInterfaceEventCache);
    InnerEmitter.on(MODULE_EVENTS.BRAIN_STATE_UPDATE, _onBrainStateUpdate);
    InnerEmitter.on(MODULE_EVENTS.AWS_GROUPS_UPDATE_PROCESSED, _onAWSGroupsUpdateProcessed);
    InnerEmitter.on(MODULE_EVENTS.CONNECTED_USERS, _onConnectedUsers);
    InnerEmitter.on(MODULE_EVENTS.CONNECTED_SERVICES, _onConnectedServices);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED, _onUpdateMiscSettingsProcessed);

    /**
     * Noder
     */
    const _onNewHeads = onNewHeads.bind(null, setAppState);
    const _onGasEstimate = onGasEstimate.bind(null, setAppState);
    const _onBalanceUpdate = onBalanceUpdate.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.NEW_HEADS, _onNewHeads);
    InnerEmitter.on(MODULE_EVENTS.GAS_ESTIMATE, _onGasEstimate);
    InnerEmitter.on(MODULE_EVENTS.BALANCE_UPDATE, _onBalanceUpdate);

    /**
     * GoNoder
     */
    const _onNewBlockTxDatas = onNewBlockTxDatas.bind(null, setAppState);
    const _onBlockTxExtractError = onBlockTxExtractError.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.NEW_BLOCK_TX_DATAS, _onNewBlockTxDatas);
    InnerEmitter.on(MODULE_EVENTS.BLOCK_TX_EXTRACT_ERROR, _onBlockTxExtractError);

    /**
     * Worker
     */
    const _onWorkerIterationResult = onWorkerIterationResult.bind(null, setAppState);
    const _onWorkerIterationError = onWorkerIterationError.bind(null, setAppState);
    const _onEventerStatsString = onEventerStatsString.bind(null, setAppState);
    const _onWorkerStatsString = onWorkerStatsString.bind(null, setAppState);
    const _onWorkerAnalyticsString = onWorkerAnalyticsString.bind(null, setAppState);
    const _onWorkerEventsString = onWorkerEventsString.bind(null, setAppState);
    const _onWorkerLpStatString = onWorkerLpStatString.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.WORKER_ITERATION_RESULT, _onWorkerIterationResult);
    InnerEmitter.on(MODULE_EVENTS.WORKER_ITERATION_ERROR, _onWorkerIterationError);
    InnerEmitter.on(MODULE_EVENTS.EVENTER_STATS_STRING, _onEventerStatsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_STATS_STRING, _onWorkerStatsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_ANALYTICS_STRING, _onWorkerAnalyticsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_EVENTS_STRING, _onWorkerEventsString);
    InnerEmitter.on(MODULE_EVENTS.WORKER_LP_STAT_STRING, _onWorkerLpStatString);

    /**
     * Transactions
     */
    const _onTxsListArchivedResponse = onTxsListArchivedResponse.bind(null, setAppState);
    const _onTxsListArchivedItemResponse = onTxsListArchivedItemResponse.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_LIST_ARCHIVED_RESPONSE, _onTxsListArchivedResponse);
    InnerEmitter.on(
      MODULE_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE,
      _onTxsListArchivedItemResponse,
    );

    /**
     * Configs
     */
    const _onListJsonConfigsResult = onListJsonConfigsResult.bind(null, setAppState);
    const _onRequestJsonConfigsResult = onRequestJsonConfigsResult.bind(null, setAppState);
    const _onUpdateJsonConfigsResult = onUpdateJsonConfigsResult.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.LIST_JSON_CONFIGS_RESULT, _onListJsonConfigsResult);
    InnerEmitter.on(MODULE_EVENTS.REQUEST_JSON_CONFIG_RESULT, _onRequestJsonConfigsResult);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_JSON_CONFIG_RESULT, _onUpdateJsonConfigsResult);

    /**
     * Monitoring
     */
    const _onUpdateNewTokensProcessed = onUpdateNewTokensProcessed.bind(null, setAppState);
    const _onUpdateCompetitorsProcessed = onUpdateCompetitorsProcessed.bind(null, setAppState);

    InnerEmitter.on(
      MODULE_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED,
      _onUpdateNewTokensProcessed,
    );
    InnerEmitter.on(
      MODULE_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED,
      _onUpdateCompetitorsProcessed,
    );

    /**
     * Rest
     */
    const _onNotificationToClient = onNotificationToClient.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.NOTIFICATION_TO_CLIENT, _onNotificationToClient);

    const _onCreateTokenProcessed = onCreateTokenProcessed.bind(null, setAppState);
    const _onUpdateTokenProcessed = onUpdateTokenProcessed.bind(null, setAppState, toastDataUpdate);
    const _onDeleteTokenProcessed = onDeleteTokenProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.CREATE_TOKEN_PROCESSED, _onCreateTokenProcessed);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_TOKEN_PROCESSED, _onUpdateTokenProcessed);
    InnerEmitter.on(MODULE_EVENTS.DELETE_TOKEN_PROCESSED, _onDeleteTokenProcessed);

    const _onCreateBridgeProcessed = onCreateBridgeProcessed.bind(null, setAppState);
    const _onUpdateBridgeProcessed = onUpdateBridgeProcessed.bind(null, setAppState);
    const _onDeleteBridgeProcessed = onDeleteBridgeProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.CREATE_BRIDGE_PROCESSED, _onCreateBridgeProcessed);
    InnerEmitter.on(MODULE_EVENTS.UPDATE_BRIDGE_PROCESSED, _onUpdateBridgeProcessed);
    InnerEmitter.on(MODULE_EVENTS.DELETE_BRIDGE_PROCESSED, _onDeleteBridgeProcessed);

    const _onUpdateAutomationRulesProcessed = onUpdateAutomationRulesProcessed.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED, _onUpdateAutomationRulesProcessed);

    const _onGetTxsConfResult = onGetTxsConfResult.bind(null, setAppState);
    InnerEmitter.on(MODULE_EVENTS.FETCH_TXS_CONFIG_RESULT, _onGetTxsConfResult);

    // const _onCreateMinCoeffRuleProcessed = onCreateMinCoeffRuleProcessed.bind(null, setAppState)
    // const _onUpdateMinCoeffRuleProcessed = onUpdateMinCoeffRuleProcessed.bind(null, setAppState)
    // const _onDeleteMinCoeffRuleProcessed = onDeleteMinCoeffRuleProcessed.bind(null, setAppState)

    // InnerEmitter.on(
    // 	MODULE_EVENTS.CREATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
    // 	_onCreateMinCoeffRuleProcessed
    // )
    // InnerEmitter.on(
    // 	MODULE_EVENTS.UPDATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
    // 	_onUpdateMinCoeffRuleProcessed
    // )
    // InnerEmitter.on(
    // 	MODULE_EVENTS.DELETE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
    // 	_onDeleteMinCoeffRuleProcessed
    // )

    // const _onCreateTokenBridgeRuleProcessed = onCreateTokenBridgeRuleProcessed.bind(null, setAppState)
    // const _onUpdateTokenBridgeRuleProcessed = onUpdateTokenBridgeRuleProcessed.bind(null, setAppState)
    // const _onDeleteTokenBridgeRuleProcessed = onDeleteTokenBridgeRuleProcessed.bind(null, setAppState)

    // InnerEmitter.on(
    // 	MODULE_EVENTS.CREATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
    // 	_onCreateTokenBridgeRuleProcessed
    // )
    // InnerEmitter.on(
    // 	MODULE_EVENTS.UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
    // 	_onUpdateTokenBridgeRuleProcessed
    // )
    // InnerEmitter.on(
    // 	MODULE_EVENTS.DELETE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
    // 	_onDeleteTokenBridgeRuleProcessed
    // )

    const _onTradeCreated = onTradeCreated.bind(null, setAppState);
    const _onTransactionsSent = onTransactionsSent.bind(null, setAppState);
    const _onTransactionsCreated = onTransactionsCreated.bind(null, setAppState);
    const _onTransactionsOvertaken = onTransactionsOvertaken.bind(null, setAppState);
    const _onTransactionsConfirmed = onTransactionsConfirmed.bind(null, setAppState);
    const _onTradeCompleted = onTradeCompleted.bind(null, setAppState);

    InnerEmitter.on(MODULE_EVENTS.TRADE_CREATED, _onTradeCreated);
    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_SENT, _onTransactionsSent);
    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_CREATED, _onTransactionsCreated);
    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_OVERTAKEN, _onTransactionsOvertaken);
    InnerEmitter.on(MODULE_EVENTS.TRANSACTIONS_CONFIRMED, _onTransactionsConfirmed);
    InnerEmitter.on(MODULE_EVENTS.TRADE_COMPLETED, _onTradeCompleted);

    return () => {
      InnerEmitter.off(MODULE_EVENTS.INIT_INTERFACE, _onInitInterface);

      InnerEmitter.off(MODULE_EVENTS.CREATE_TOKEN_PROCESSED, _onCreateTokenProcessed);
      InnerEmitter.off(MODULE_EVENTS.UPDATE_TOKEN_PROCESSED, _onUpdateTokenProcessed);
      InnerEmitter.off(MODULE_EVENTS.DELETE_TOKEN_PROCESSED, _onDeleteTokenProcessed);

      InnerEmitter.off(MODULE_EVENTS.CREATE_BRIDGE_PROCESSED, _onCreateBridgeProcessed);
      InnerEmitter.off(MODULE_EVENTS.UPDATE_BRIDGE_PROCESSED, _onUpdateBridgeProcessed);
      InnerEmitter.off(MODULE_EVENTS.DELETE_BRIDGE_PROCESSED, _onDeleteBridgeProcessed);

      InnerEmitter.off(
        MODULE_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED,
        _onUpdateAutomationRulesProcessed,
      );

      // InnerEmitter.off(
      // 	MODULE_EVENTS.CREATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
      // 	_onCreateMinCoeffRuleProcessed
      // )
      // InnerEmitter.off(
      // 	MODULE_EVENTS.UPDATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
      // 	_onUpdateMinCoeffRuleProcessed
      // )
      // InnerEmitter.off(
      // 	MODULE_EVENTS.DELETE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
      // 	_onDeleteMinCoeffRuleProcessed
      // )

      // InnerEmitter.off(
      // 	MODULE_EVENTS.CREATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
      // 	_onCreateTokenBridgeRuleProcessed
      // )
      // InnerEmitter.off(
      // 	MODULE_EVENTS.UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
      // 	_onUpdateTokenBridgeRuleProcessed
      // )
      // InnerEmitter.off(
      // 	MODULE_EVENTS.DELETE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
      // 	_onDeleteTokenBridgeRuleProcessed
      // )
    };
  }, []);
}

/**
 *
 */
function onInitInterface(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.INIT_INTERFACE],
) {
  const {
    devMode,
    msRemainingBeforeDisconnect,
    allTokens,
    competitors,
    bridges,
    trades,
    miscSettings,
    brainState,
    awsGroups,
    connectedServices,
  } = data;

  // for (const [tokenName, tokenData] of Object.entries(allTokens)) {
  // 	if (Object.prototype.hasOwnProperty.call(tokenData.networkData || {}, NETWORK.ARBITRUM)) {
  // 		// toggleTokenNetworkTrackIgnore(tokenName, tokenData.networkTrackIgnore, NETWORK.ARBITRUM)

  // 		sendUpdateEvent(MODULE_EVENTS.UPDATE_TOKEN, {
  // 			tokenName,
  // 			updates: {
  // 				networkTrackIgnore: {
  // 					...tokenData.networkTrackIgnore,
  // 					[NETWORK.ARBITRUM]: true,
  // 				},
  // 			},
  // 		})

  // 		console.log(tokenName)
  // 		await new Promise((r) => setTimeout(r, 100))
  // 	}
  // }
  // console.log("DONE")

  console.log('onInitInterface', data);
  setAppState((draft) => {
    draft.devMode = devMode;
    draft.msRemainingBeforeDisconnect = msRemainingBeforeDisconnect;
    draft.allTokens = allTokens;
    draft.competitors = competitors;
    draft.bridges = bridges;
    draft.trades = trades;
    draft.miscSettings = miscSettings;
    draft.brainState = brainState;
    draft.awsGroups = awsGroups;
    draft.connectedServices = connectedServices;
  });
}

/**
 *
 */
function onInterfaceEventCache(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.INTERFACE_EVENT_CACHE],
) {
  setAppState((draft) => {
    for (const key of Object.keys(data)) {
      // @ts-ignore
      draft[key] = data[key];
    }
  });
}

/**
 *
 */
function onBrainStateUpdate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.BRAIN_STATE_UPDATE],
) {
  setAppState((draft) => {
    draft.brainState = data;
  });
}

/**
 *
 */
function onAWSGroupsUpdateProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.AWS_GROUPS_UPDATE_PROCESSED],
) {
  setAppState((draft) => {
    draft.awsGroups = data.awsGroups;
  });
}

/**
 *
 */
function onConnectedUsers(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS],
) {
  setAppState((draft) => {
    draft.connectedUsers = data;
  });
}

/**
 *
 */
function onConnectedServices(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_SERVICES],
) {
  setAppState((draft) => {
    draft.connectedServices = data;
  });
}

/**
 *
 */
function onUpdateMiscSettingsProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED],
) {
  setAppState((draft) => {
    draft.miscSettings = data.miscSettings;
  });
}

/**
 *
 */
function onNewHeads(setAppState: Updater<AppState>, data: MODULE_EVENTS_PARAMS[NODER_EVENTS.NEW_HEADS]) {
  setAppState((draft) => {
    draft.newHeads[data.networkName as keyof AppState['newHeads']] = data;
  });
}

/**
 *
 */
function onGasEstimate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[NODER_EVENTS.GAS_ESTIMATE],
) {
  setAppState((draft) => {
    draft.gasEstimate[data.networkName] = data;
  });
}

/**
 *
 */
function onBalanceUpdate(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[NODER_EVENTS.BALANCE_UPDATE],
) {
  setAppState((draft) => {
    draft.balance[data.networkName] = data.balance;
  });
}

/**
 *
 */
function onNewBlockTxDatas(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.NEW_BLOCK_TX_DATAS],
) {
  // console.log("onNewBlockTxDatas", data)
  setAppState((draft) => {
    draft.goNoderNewHeads[data.networkName] = data;
  });
}

function onBlockTxExtractError(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.BLOCK_TX_EXTRACT_ERROR],
) {
  addToast(
    `${data.networkName}: tx extract error for block ${data.blockNumber}. Workers refetching all tokens`,
    { type: 'warning', autoClose: 4000 },
  );
}

/**
 *
 */
function onWorkerIterationResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT],
) {
  setAppState((draft) => {
    for (const [tokenName, res] of Object.entries(data)) {
      draft.trackingResults[tokenName] = res;
    }
  });
}

function onWorkerIterationError(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_ERROR],
) {
  setAppState((draft) => {
    for (const [tokenName, res] of Object.entries(data)) {
      draft.trackingErrors[tokenName] = res;
    }
  });
}

function onEventerStatsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_EVENTS_STRING],
) {
  setAppState((draft) => {
    draft.eventerStatsString = data;
  });
}

function onWorkerStatsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_STATS_STRING],
) {
  setAppState((draft) => {
    draft.workerStatsString = data;
  });
}

function onWorkerAnalyticsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ANALYTICS_STRING],
) {
  setAppState((draft) => {
    draft.workerAnalyticsString = data;
  });
}

function onWorkerEventsString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_EVENTS_STRING],
) {
  setAppState((draft) => {
    draft.workerEventsString = data;
  });
}

function onWorkerLpStatString(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_LP_STAT_STRING],
) {
  setAppState((draft) => {
    draft.workerLpStatString = data;
  });
}

/**
 *
 */
function onTxsListArchivedResponse(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_RESPONSE],
) {
  setAppState((draft) => {
    draft.archivedTxsFileNames = data;
  });
}

/**
 *
 */
function onTxsListArchivedItemResponse(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE],
) {
  setAppState((draft) => {
    draft.archivedTxs[data.fileName] = data.data;
    draft.currentArchiveFileName = data.fileName;
  });
}

/**
 *
 */
function onUpdateNewTokensProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[MONITORING_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED],
) {
  setAppState((draft) => {
    // draft.newTokens = data
  });
}

/**
 *
 */
function onUpdateCompetitorsProcessed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[MONITORING_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED],
) {
  setAppState((draft) => {
    draft.competitors = data;
  });
}

/**
 *
 */
function onNotificationToClient(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.NOTIFICATION_TO_CLIENT],
) {
  if (data.autoClose === 60000) {
    const f = async () => {
      const num = 14 + Math.floor(Math.random() * 8);
      for (let i = 0; i < num; i++) {
        Sound.play('dindilin');
        await new Promise(r => setTimeout(r, 50));
      }
    };
    f();
  }
  addToast(data.msg, { type: data.type, autoClose: data.autoClose });
}

/**
 *
 */
function onCreateTokenProcessed(
  setAppState: Updater<AppState>,
  { tokenName, tokenData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CREATE_TOKEN_PROCESSED],
) {
  setAppState((appState) => {
    appState.allTokens[tokenName] = tokenData;
  });
}

/**
 *
 */
function onUpdateTokenProcessed(
  setAppState: Updater<AppState>,
  toastDataUpdate: (text: string) => void,
  { tokenName, tokenData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_TOKEN_PROCESSED],
) {
  // toastDataUpdate(`Token ${tokenName} updated`)
  setAppState((appState) => {
    appState.allTokens[tokenName] = tokenData;
  });
}

/**
 *
 */
function onDeleteTokenProcessed(
  setAppState: Updater<AppState>,
  { tokenName }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_TOKEN_PROCESSED],
) {
  setAppState((appState) => {
    delete appState.allTokens[tokenName];
  });
}

/**
 *
 */
function onCreateBridgeProcessed(
  setAppState: Updater<AppState>,
  { bridgeName, bridgeData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CREATE_BRIDGE_PROCESSED],
) {
  setAppState((appState) => {
    appState.bridges[bridgeName] = bridgeData;
  });
}

/**
 *
 */
function onUpdateBridgeProcessed(
  setAppState: Updater<AppState>,
  { bridgeName, bridgeData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_BRIDGE_PROCESSED],
) {
  setAppState((appState) => {
    appState.bridges[bridgeName] = bridgeData;
  });
}

/**
 *
 */
function onDeleteBridgeProcessed(
  setAppState: Updater<AppState>,
  { bridgeName, updatedTokens }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_BRIDGE_PROCESSED],
) {
  setAppState((appState) => {
    delete appState.bridges[bridgeName];
    appState.allTokens = {
      ...appState.allTokens,
      ...updatedTokens,
    };
  });
}

/**
 *
 */
function onUpdateAutomationRulesProcessed(
  setAppState: Updater<AppState>,
  { rules }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED],
) {
  setAppState((appState) => {
    appState.automationRules = rules;
  });
}

// /**
//  *
//  */
// function onCreateMinCoeffRuleProcessed(
// 	setAppState: Updater<AppState>,
// 	{ gasPrice, ruleData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CREATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED]
// ) {
// 	setAppState((appState) => {
// 		if (!appState.automationRules) return
// 		appState.automationRules.minCoeffRules[gasPrice] = ruleData
// 	})
// }

// /**
//  *
//  */
// function onUpdateMinCoeffRuleProcessed(
// 	setAppState: Updater<AppState>,
// 	{ gasPrice, ruleData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED]
// ) {
// 	setAppState((appState) => {
// 		if (!appState.automationRules) return
// 		appState.automationRules.minCoeffRules[gasPrice] = ruleData
// 	})
// }

// /**
//  *
//  */
// function onDeleteMinCoeffRuleProcessed(
// 	setAppState: Updater<AppState>,
// 	{ gasPrice }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_AUTOMATION_MIN_COEFF_RULE_PROCESSED]
// ) {
// 	setAppState((appState) => {
// 		if (!appState.automationRules) return
// 		delete appState.automationRules.minCoeffRules[gasPrice]
// 	})
// }

// /**
//  *
//  */
// function onCreateTokenBridgeRuleProcessed(
// 	setAppState: Updater<AppState>,
// 	{ type, name, ruleData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CREATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED]
// ) {
// 	setAppState((appState) => {
// 		if (!appState.automationRules) return
// 		if (type === "token") {
// 			appState.automationRules.tokenRules = appState.automationRules.tokenRules || {}
// 			appState.automationRules.tokenRules[name] = ruleData
// 		} else if (type === "bridge") {
// 			appState.automationRules.bridgeRules = appState.automationRules.bridgeRules || {}
// 			appState.automationRules.bridgeRules[name] = ruleData
// 		}
// 	})
// }

// /**
//  *
//  */
// function onUpdateTokenBridgeRuleProcessed(
// 	setAppState: Updater<AppState>,
// 	{ type, name, ruleData }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED]
// ) {
// 	setAppState((appState) => {
// 		if (!appState.automationRules) return
// 		if (type === "token") {
// 			appState.automationRules.tokenRules = appState.automationRules.tokenRules || {}
// 			appState.automationRules.tokenRules[name] = ruleData
// 		} else if (type === "bridge") {
// 			appState.automationRules.bridgeRules = appState.automationRules.bridgeRules || {}
// 			appState.automationRules.bridgeRules[name] = ruleData
// 		} else if (type === "default") {
// 			appState.automationRules.default = ruleData as Required<DBAutomationRule>
// 		}
// 	})
// }

// /**
//  *
//  */
// function onDeleteTokenBridgeRuleProcessed(
// 	setAppState: Updater<AppState>,
// 	{ type, name }: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.DELETE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED]
// ) {
// 	setAppState((appState) => {
// 		if (!appState.automationRules) return
// 		if (type === "token") {
// 			delete appState.automationRules.tokenRules?.[name]
// 		} else if (type === "bridge") {
// 			delete appState.automationRules.bridgeRules?.[name]
// 		}
// 	})
// }

/**
 *
 */
function onTradeCreated(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRADE_CREATED],
) {
  setAppState((appState) => {
    appState.trades[data.id!] = data as any;
  });
}

function onTransactionsSent(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_SENT],
) {
  Sound.play('dindilin');

  for (const tx of data) {
    infoToast(`${tx?.tokenName} (${tx?.txType}) CREATED`, {
      autoClose: 10000,
    });
  }
}

function onTransactionsCreated(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_CREATED],
) {
  setAppState((appState) => {
    if (!!data.id && !!data.txs) {
      if (!appState.trades[data.id]) {
        appState.trades[data.id] = data as any;
      } else {
        appState.trades[data.id].txs = data.txs;
      }
    }
  });
}

function onTransactionsOvertaken(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_OVERTAKEN],
) {
  Sound.play('beep');
  infoToast('Transaction overtaken', {
    autoClose: 10000,
  });
  setAppState((appState) => {
    if (!!data.id && !!data.txs) {
      for (const txHash in data.txs) {
        appState.trades[data.id].txs[txHash] = {
          ...appState.trades[data.id!].txs[txHash],
          ...data.txs![txHash],
        };
      }
    }
  });
}

function onTransactionsConfirmed(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRANSACTIONS_CONFIRMED],
) {
  Sound.play('beep');
  setTimeout(() => {
    Sound.play('beep');
  }, 200);

  for (const txHash in data.txs) {
    const tx = data.txs[txHash];
    if (tx.success === false) {
      if (
        !!tx.errMessage &&
        (tx.errMessage.includes('coleasce error') || tx.errMessage.includes('already known'))
      ) {
        warnToast(`${tx.tokenName} (${tx.txType}) status unknown, check explorer`, {
          autoClose: 10000,
        });
      } else {
        errorToast(`${tx.tokenName} (${tx.txType}) FAILED`, {
          autoClose: 10000,
        });
      }
    } else {
      infoToast(`${tx.tokenName} (${tx.txType}) CONFIRMED`, {
        autoClose: 10000,
      });
    }
  }

  setAppState((appState) => {
    const timeHash = Date.now().toString(10);
    for (const txHash of Object.keys(data.txs || {})) {
      if (!data.id) {
        data.id = timeHash;
      }
      if (!appState.trades[data.id]) {
        appState.trades[data.id] = { txs: {} as any } as any;
      }
      // @ts-ignore
      appState.trades[data.id].txs[txHash] = {
        ...appState.trades[data.id!].txs[txHash],
        ...data.txs![txHash],
      };
    }
  });
}

function onTradeCompleted(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[TRANSACTIONS_EVENTS.TRADE_COMPLETED],
) {
  setAppState((appState) => {
    if (!!data.id && !!data.completionTime && appState.trades[data.id ?? '']) {
      appState.trades[data.id].completionTime = data.completionTime;
    }
  });
}

/**
 *
 */
function onGetTxsConfResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.FETCH_TXS_CONFIG_RESULT],
) {
  setAppState((draft) => {
    if (!draft.configs) {
      draft.configs = { transactions: yaml.dump(data) };
    } else {
      draft.configs.transactions = yaml.dump(data);
    }
  });
}

function onListJsonConfigsResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.LIST_JSON_CONFIGS_RESULT],
) {
  setAppState(draft => {
    draft.configsJson = Object.fromEntries(data.configNames.map(name => ([name, undefined])));
  });
}

function onRequestJsonConfigsResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.REQUEST_JSON_CONFIG_RESULT],
) {
  setAppState(draft => {
    draft.configsJson[data.configName] = data.configData;
  });
}

function onUpdateJsonConfigsResult(
  setAppState: Updater<AppState>,
  data: MODULE_EVENTS_PARAMS[INTERFACE_EVENTS.UPDATE_JSON_CONFIG_RESULT],
) {
  setAppState(draft => {
    draft.configsJson[data.configName] = data.configData;
  });
}