import { memo, useEffect, useState } from "react"

import { Button, Col, Container, Row, Stack } from "react-bootstrap"

import AceEditor from "react-ace"
import "ace-builds/webpack-resolver"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-monokai"
import "ace-builds/src-min-noconflict/ext-language_tools"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, { ContentSection, ContentSpacer } from "../../../components/Content"
import Sidebar, { SidebarRootNavLinks, SidebarSection } from "../../../components/Sidebar"

import { AppState, sendUpdateEvent } from "../../../App"
import { SidebarSettingsNavLinks } from ".."

import { Updater } from "use-immer"
import { listJsonConfigs, requestJsonConfig, updateJsonConfig } from "./actions"
import { MODULE_EVENTS } from "../../../common"

/**
 * Configs settings page
 *  Route '/settings/configsJson'
 */
type SettingsConfigsProps = {
	configsJson: AppState["configsJson"]
	setAppState: Updater<AppState>
}
export default memo(function SettingsConfigsJson({ configsJson, setAppState }: SettingsConfigsProps) {
	// const [currentConfName, setCurrentConfName] = useState<string | null>(null)
	const [currentConfName, setCurrentConfName] = useState<string | null>(null)

	useEffect(() => {
		listJsonConfigs()
	}, [])

	useEffect(() => {
		const keys = Object.keys(configsJson)
		if (currentConfName === null && keys.length > 0) {
			setCurrentConfName(keys[0])
		}
	}, [currentConfName, configsJson])

	useEffect(() => {
		if (currentConfName === null) return
		requestJsonConfig(currentConfName)
	}, [currentConfName])

	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarSettingsNavLinks />

							<SidebarSection renderP gap={1}>
								<SideButtons
									confNames={Object.keys(configsJson)}
									currentConfName={currentConfName}
									setCurrentConfName={setCurrentConfName}
								/>
							</SidebarSection>
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>
							<ContentSection>
								<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
									<div style={{ width: "60%", marginRight: 24 }}>
										{!!currentConfName && !!configsJson[currentConfName] && (
											<>
												<AceEditor
													mode="json"
													theme="monokai"
													value={configsJson[currentConfName]}
													style={{
														width: "100%",
														height: "80vh",
													}}
													onChange={(val) => {
														setAppState((draft) => {
															try {
																draft.configsJson[currentConfName] = val
															} catch (e) {}
														})
													}}
													name="conf-editor"
													editorProps={{ $blockScrolling: true }}
												/>
												<Button
													size="sm"
													variant="success"
													style={{
														width: 200,
														marginTop: 24,
													}}
													onClick={() => {
														if (configsJson[currentConfName] !== undefined) {
															try {
																const valUpdated = JSON.stringify(
																	JSON.parse(configsJson[currentConfName])
																)
																updateJsonConfig(currentConfName, valUpdated)
															} catch (e) {
																sendUpdateEvent(
																	MODULE_EVENTS.NOTIFICATION_TO_CLIENT,
																	{
																		type: "warning",
																		msg: "Cannot update config, check for json errors",
																		autoClose: 5000,
																	}
																)
															}
														}
													}}
												>
													Update Config
												</Button>
											</>
										)}
									</div>
									<div style={{ width: "40%", fontSize: 14 }}>
										<h5>Description for some of the config fields</h5>
										<br />
										<p>
											<b>ANY SLIPPAGE IS DEFINED IN BPS.</b> - 0.5% = 50bps, 1% = 100bps
											etc.
										</p>
										<br />
										<p>
											<b>iterationNetworks</b> - list of networks where iterations are
											enabled.
										</p>
										<p>
											<b>iterations</b> - enable or disable iterations.
										</p>
										<br />
										<p>
											Updates inside <b>UPDATES_REQUIRING_RESTART</b> require worker
											restart.
										</p>
									</div>
								</div>
							</ContentSection>
							<ContentSection>
								<ContentSpacer height={100} />
							</ContentSection>
						</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
})

/**
 *
 */
type SideButtonsProps = {
	confNames: string[]
	currentConfName: string | null
	setCurrentConfName: any
}
const SideButtons = memo(function SideButtons({
	confNames,
	currentConfName,
	setCurrentConfName,
}: SideButtonsProps) {
	return (
		<Stack direction="vertical" gap={3}>
			{confNames.map((confName) => (
				<Button
					key={confName}
					size="sm"
					variant={currentConfName === confName ? "success" : "outline-secondary"}
					onClick={() => setCurrentConfName(confName)}
				>
					{confName.charAt(0).toUpperCase() + confName.slice(1)}
				</Button>
			))}
		</Stack>
	)
})
