import React, { useEffect } from "react"

import { useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Container, Stack } from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../components/Header"
import Content from "../../components/Content"
import Sidebar, { SidebarLink, SidebarRootNavLinks, SidebarSection } from "../../components/Sidebar"

/**
 * Settings navigation links conponent
 */
export function SidebarSettingsNavLinks() {
	return (
		<SidebarSection name="Settings navigation">
			<Stack gap={2}>
				<SidebarLink icon="bitcoin" text="Tokens" href="/settings/tokens" />
				<SidebarLink icon="competitor" text="Competitors" href="/settings/competitors" />
				<SidebarLink icon="bridge" text="Bridges" href="/settings/bridges" />
				<SidebarLink icon="laptopCode" text="Automation" href="/settings/automation" />
				<SidebarLink icon="aws" text="AWS" href="/settings/aws" />
				<SidebarLink icon="config" text="Configs" href="/settings/configs" />
				<SidebarLink icon="config" text="ConfigsJson" href="/settings/configsJson" />
			</Stack>
		</SidebarSection>
	)
}

/**
 * Settings page
 *  Route '/settings'
 */
export default function Settings() {
	const location = useLocation()
	const navigate = useNavigate()

	/**
	 * Redirect user to specific setting page
	 */
	useEffect(() => {
		const { pathname } = location

		if (pathname.replaceAll("/", "") === "settings") {
			navigate("./tokens")
		}
	}, [location.pathname])

	/**
	 * Render is here only to keep the sidebar with some items
	 *  so that the page is not blank while user is being redirected
	 */
	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarSettingsNavLinks />
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>{/* should be empty  */}</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
}
