import * as yaml from "js-yaml"

import {sendUpdateEvent} from "../../../App"
import {MODULE_EVENTS} from "../../../common"

/**
 *
 */
export async function fetchTxsConf() {
    sendUpdateEvent(MODULE_EVENTS.FETCH_TXS_CONFIG, {})
}

/**
 *
 */
export async function updateTxsConf(data: string) {
    const body: any = yaml.load(data)
    sendUpdateEvent(MODULE_EVENTS.UPDATE_TXS_CONFIG, body)
}
