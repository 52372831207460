import { ReactNode, memo, useContext, useState } from "react"

import { Badge, Button, Col, Modal, Row, Stack } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { Link } from "react-router-dom"

import unicorn from "../assets/unicorn.gif"
import typing from "../assets/typing.gif"

import { Color } from "react-bootstrap/esm/types"
import { AppContext } from "../App"
import { sendUpdateEvent } from "../App"
import { MODULE_EVENTS } from "../common/moduleEvents"
import Popup, { SwalStyled } from "../helper/Popup"
import { getNetworkImage } from "../assets/networks"
import { NETWORK, NETWORK_EXPLORER_ADDRESS } from "../common"
import SocketClient from "../common_custom/SocketClient"
import React from "react"

/**
 *
 */
type HeaderSectionProps = {
	justifyContent?: "start" | "end" | "center"
	children?: ReactNode
}
export const HeaderSection = memo(function HeaderSection({ children, justifyContent }: HeaderSectionProps) {
	return (
		<Col className={"d-flex gap-2 " + (justifyContent ? `justify-content-${justifyContent}` : "")}>
			{children}
		</Col>
	)
})

/**
 *
 */
type HeaderButtonProps = {
	img?: string
	text: string | JSX.Element
	hoverText?: string | JSX.Element
	variant?: string /** [outline-]primary etc */
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}
export const HeaderButton = memo(function HeaderButton({
	img,
	text,
	hoverText,
	variant,
	onClick,
}: HeaderButtonProps) {
	const [hover, setHover] = useState(false)
	return (
		<Button
			style={{ display: "flex", alignItems: "center" }}
			size="sm"
			variant={variant || "light"}
			disabled={!onClick}
			onClick={onClick}
			onMouseEnter={() => {
				setHover(true)
			}}
			onMouseLeave={() => {
				setHover(false)
			}}
		>
			{img && <img width="14px" src={img} alt="" style={{ marginRight: "4px" }} />}
			<span>{hover ? hoverText || text : text}</span>
		</Button>
	)
})

/**
 *
 */
type HeaderBadgeProps = {
	text?: string
	textColor?: Color
	bgColor?: Color
	children?: ReactNode
}
export const HeaderBadge = memo(function HeaderBadge({
	text,
	textColor,
	bgColor,
	children,
}: HeaderBadgeProps) {
	return (
		<Badge bg={bgColor} text={textColor} className="d-flex" style={{ alignItems: "center" }}>
			<Stack direction="horizontal" gap={1}>
				{text}
				{children}
			</Stack>
		</Badge>
	)
})

export const DevAppend = memo(function DevAppend() {
	return <span style={{ color: "red", fontWeight: "bold" }}>DEV DB</span>
})

export const BuildAppend = memo(function BuildAppend({ buildNumber }: { buildNumber?: string }) {
	return (
		<span style={{ color: "#adb5bd", fontSize: "0.7em", marginLeft: "10px" }}>
			{buildNumber ? "#" + buildNumber : ""}
		</span>
	)
})

export function getLogoSrc() {
	if (process.env.NODE_ENV === "production") {
		return unicorn
	}
	return typing
}

/**
 *
 */
type HeaderProps = {
	children?: ReactNode
}
export default memo(function Header_C({ children }: HeaderProps) {
	const { projectName, devMode } = useContext(AppContext)
	const append = devMode ? (
		<DevAppend />
	) : (
		<BuildAppend buildNumber={process.env.NODE_ENV === "production" ? "prod" : "dev"} />
	)

	return (
		<Container fluid className="headerContainer">
			<Row>
				<Col className="sidebarOffset">
					<Container>
						<Link to="/" className="d-grid" style={{ textDecoration: "none" }}>
							<Button className="text-start d-flex align-items-center gap-2" variant="light">
								<img className={"logoImg"} src={getLogoSrc()}></img>
								{projectName || "Title"}
								{append}
							</Button>
						</Link>
					</Container>
				</Col>
				<Col>
					<Container fluid>
						<Row>{children}</Row>
					</Container>
				</Col>
			</Row>
		</Container>
	)
})

function WorkersModal() {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	return (
		<>
			<Button size="sm" variant="outline-primary" onClick={handleShow}>
				Worker
			</Button>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Worker actions</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
					<Button
						onClick={() => {
							SocketClient.emitEvent(MODULE_EVENTS.COMMAND, { to: "worker", cmd: "saveStats" })
							setTimeout(() => handleClose(), 250)
						}}
					>
						Save file
					</Button>
					<Button
						onClick={() => {
							SocketClient.emitEvent(MODULE_EVENTS.COMMAND, { to: "worker", cmd: "clearBan" })
							setTimeout(() => handleClose(), 250)
						}}
					>
						Clear ban
					</Button>
					<Button
						onClick={() => {
							SocketClient.emitEvent(MODULE_EVENTS.COMMAND, {
								to: "worker",
								cmd: "recreateClients",
							})
							setTimeout(() => handleClose(), 250)
						}}
					>
						Recreate clients
					</Button>
				</Modal.Body>
			</Modal>
		</>
	)
}

/**
 *
 */
type HeaderStartStopSectionProps = {}
export const HeaderStartStopSection_C = memo(
	function HeaderStopStartSection({}: HeaderStartStopSectionProps) {
		const { brainState } = useContext(AppContext)
		const { workersRunning, routeSearchRunning, routeSearchProgress } = brainState

		const combinedRouteSearchProgress =
			Object.values(routeSearchProgress || {}).reduce((acc, cur) => acc + cur, 0) /
			Object.keys(routeSearchProgress || {}).length

		if (workersRunning) {
			return (
				<>
					<HeaderButton
						text="Stop"
						variant="outline-danger"
						onClick={
							routeSearchRunning
								? undefined
								: () => {
										sendUpdateEvent(MODULE_EVENTS.CLIENT_STOP_WORKERS, undefined)
								  }
						}
					/>
					<HeaderBadge bgColor="light" textColor="muted">
						<HeaderBadge text="working" bgColor="success" />
					</HeaderBadge>

					<WorkersModal />
				</>
			)
		} else {
			return (
				<>
					<HeaderButton
						text="Start"
						variant="outline-success"
						onClick={
							routeSearchRunning
								? undefined
								: () => {
										sendUpdateEvent(MODULE_EVENTS.CLIENT_START_WORKERS, undefined)
								  }
						}
					/>
					<HeaderButton
						text={
							routeSearchRunning
								? `Finding routes: ${(combinedRouteSearchProgress * 100).toFixed(2)}%`
								: `Find routes`
						}
						variant="outline-primary"
						onClick={
							routeSearchRunning
								? undefined
								: (e) => {
										if (e.shiftKey) {
											sendUpdateEvent(
												MODULE_EVENTS.CLIENT_START_ROUTE_SEARCH,
												undefined
											)
										}
								  }
						}
					/>
					<HeaderBadge bgColor="light" textColor="muted">
						<HeaderBadge text="stopped" bgColor="danger" />
					</HeaderBadge>
				</>
			)
		}
	}
)

/**
 *
 */
type HeaderConnectedServicesSectionProps = {}
export const HeaderConnectedServicesSection_C = memo(
	function HeaderConnectedServicesSection({}: HeaderConnectedServicesSectionProps) {
		const { connectedServices } = useContext(AppContext)

		const entries = Object.entries(connectedServices).sort((a, b) => a[0].localeCompare(b[0]))

		return (
			<>
				{entries.map(([serviceName, service]) => {
					const { numConnected, numExpected, hasSubService, subServiceOk } = service

					if (numConnected === numExpected) {
						if (hasSubService) {
							return (
								<HeaderButton
									key={serviceName}
									text={`${serviceName[0]}: ${numConnected}`}
									variant={subServiceOk ? "success" : "warning"}
									onClick={() => {
										Popup.confirm({
											title: `Restart ${serviceName}`,
											text: `Are you sure you want to restart ${serviceName}?`,
											onConfirm: () => {
												sendUpdateEvent(MODULE_EVENTS.RESTART_SERVICE, {
													serviceName,
												})
											},
										})
									}}
								/>
							)
						} else {
							return (
								<HeaderButton
									key={serviceName}
									text={`${serviceName[0]}: ${numConnected}`}
									variant="success"
									onClick={() => {
										Popup.confirm({
											title: `Restart ${serviceName}`,
											text: `Are you sure you want to restart ${serviceName}?`,
											onConfirm: () => {
												sendUpdateEvent(MODULE_EVENTS.RESTART_SERVICE, {
													serviceName,
												})
											},
										})
									}}
								/>
							)
						}
					}

					return (
						<HeaderButton
							key={serviceName}
							text={`${serviceName[0]}: ${numConnected} / ${numExpected}`}
							variant="danger"
							onClick={() => {
								Popup.confirm({
									title: `Restart ${serviceName}`,
									text: `Are you sure you want to restart ${serviceName}?`,
									onConfirm: () => {
										sendUpdateEvent(MODULE_EVENTS.RESTART_SERVICE, { serviceName })
									},
								})
							}}
						/>
					)
				})}
			</>
		)
	}
)

/**
 *
 */
type HeaderBlockNumSectionProps = {}
export const HeaderBlockNumSection_C = memo(function HeaderBlockNumSection({}: HeaderBlockNumSectionProps) {
	const { newHeads, goNoderNewHeads } = useContext(AppContext)
	const networkNames = Array.from(new Set([...Object.keys(newHeads), ...Object.keys(goNoderNewHeads)]))

	return (
		<>
			{/* <div style={{ position: 'absolute', right: '0', top: '0', display: 'flex' }}> */}
			{networkNames
				.sort((a, b) => a.localeCompare(b))
				.map((_networkName) => {
					const networkName = _networkName as NETWORK

					const bnString = (newHeads[networkName]?.blockNumber ?? -1).toString()
					const bnLocaleString = (newHeads[networkName]?.blockNumber ?? -1).toLocaleString()

					const goNoderBnString = parseInt(
						goNoderNewHeads[networkName]?.blockNumber ?? "-1"
					).toString()
					const goNoderBnLocaleString = parseInt(
						goNoderNewHeads[networkName]?.blockNumber ?? "-1"
					).toLocaleString()
					return (
						<HeaderButton
							key={networkName}
							img={getNetworkImage(networkName)}
							text={
								<>
									<Stack
										direction="vertical"
										style={{ position: "relative", fontVariantNumeric: "tabular-nums" }}
									>
										<span>
											{bnString.length > 2
												? bnString.slice(bnString.length - 2)
												: bnString}
										</span>
										<span
											style={{
												position: "absolute",
												top: 0,
												right: 0,
												fontSize: "0.6em",
												marginTop: "-6px",
											}}
										>
											{goNoderBnString.length > 2
												? goNoderBnString.slice(goNoderBnString.length - 2)
												: goNoderBnString}
										</span>
									</Stack>
								</>
							}
							hoverText={
								<>
									<Stack
										direction="vertical"
										style={{ position: "relative", fontVariantNumeric: "tabular-nums" }}
									>
										<span>{bnLocaleString}</span>
										<span
											style={{
												position: "absolute",
												top: 0,
												right: 0,
												fontSize: "0.6em",
												marginTop: "-6px",
											}}
										>
											{goNoderBnLocaleString}
										</span>
									</Stack>
								</>
							}
							onClick={() => {
								window.open(
									`${NETWORK_EXPLORER_ADDRESS[networkName]}/block/${bnString}`,
									"_blank"
								)
							}}
						/>
					)
				})}
			{/* </div> */}
		</>
	)
})
