import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Badge, Button, Stack } from 'react-bootstrap';

import VirtualTable, { VirtualTableRefProps } from '../../../components/VirtualTable';
import { SettingsTokensFilterData, SettingsTokensSortingData } from '.';

import {
  DBBridges,
  DBMiscSettings,
  DBToken,
  DBTokens,
  getTokenNetworkTrackIgnore,
  NETWORK,
  NETWORK_EXPLORER_ADDRESS,
  REQUESTER,
} from '../../../common';

import {
  addTokenBridge,
  addTokenBridgeNetwork,
  addTokenMinProfit,
  addTokenNetwork,
  addTokenNetworkSlippage,
  addTokenSelectedRequester,
  addTokenTradeSlippage,
  deleteToken,
  deleteTokenBridge,
  deleteTokenBridgeNetwork,
  deleteTokenMinProfit,
  deleteTokenNetwork,
  deleteTokenNetworkSlippage,
  deleteTokenSelectedRequester,
  deleteTokenTradeSlippage,
  editTokenMinProfit,
  editTokenNetworkSlippage,
  editTokenTradeSlippage,
  toggleTokenNetworkTrackIgnore,
  updateBridgeAutomation,
  updateBridgePriority,
  updateTokenActive,
  updateTokenAutomation,
  updateTokenCheapBribe,
  updateTokenComments,
  updateTokenExpensiveBribe,
  updateTokenHidden,
  updateTokenMaxTrackingValue,
  updateTokenMinTrackingValue,
  updateTokenNetworkAddress,
  updateTokenNetworkDecimals,
  updateTokenSlippage,
  updateTokenTrackingValue,
} from './actions';

import { eye, eyeSlash } from '../../../helper/svg';
import { getNetworkImage } from '../../../assets/networks';
import { getAggregatorImage } from '../../../assets/aggregators';

const columns = [
  { name: 'En', key: 'enabled', tooltip: 'Enabled', minWidth: 40 },
  { name: 'Token', key: 'token', tooltip: 'Token Name', minWidth: 150 },
  { name: 'A', key: 'automation', tooltip: 'Automation Enabled', minWidth: 40 },
  { name: eye as any, key: 'hidden', tooltip: 'Token Hidden', minWidth: 40 },
  // { name: "NA", key: "network_automation", minWidth: 40 },
  { name: 'N', key: 'network', tooltip: 'Network', minWidth: 40 },
  { name: 'Address', key: 'address', minWidth: 140 },
  { name: 'D', key: 'decimals', tooltip: 'Decimals', minWidth: 40 },
  { name: 'E', key: 'expensive', tooltip: 'Expensive Bribe', minWidth: 40 },
  { name: 'L', key: 'cheap', tooltip: 'Low (Cheap) Bribe', minWidth: 40 },
  { name: '+', key: 'add_network', tooltip: 'Add Network', minWidth: 30 },
  {
    name: 'Requesters',
    key: 'selected_requesters',
    tooltip: 'Selected Requesters',
    minWidth: Object.keys(REQUESTER).length * 36,
  },
  { name: 'Val', key: 'value', minWidth: 40 },
  { name: 'Min Track', key: 'minTracking', minWidth: 60 },
  { name: 'Max Track', key: 'maxTracking', minWidth: 60 },
  { name: 'Slip', key: 'slippage', minWidth: 80 },
  { name: 'Trade_Slipp %', key: 'trade_slippage', minWidth: 200 },
  { name: '+', key: 'add_trade_slippage', tooltip: 'Add Trade Slippage', minWidth: 30 },
  { name: 'Min Profit', key: 'min_profit', minWidth: 150 },
  { name: '+', key: 'add_min_profit', tooltip: 'Add Min Profit', minWidth: 30 },
  // { name: "N_Slipp", key: "network_slippage", minWidth: 200 },
  // { name: "+", key: "add_network_slippage", minWidth: 30 },
  // { name: "Old bridge", key: "bridge_name_old", minWidth: 160 },
  { name: 'Bridge', key: 'bridge_name', minWidth: 200 },
  { name: 'Bridge Networks', key: 'bridge_networks', minWidth: Object.keys(NETWORK).length * 36 },
  { name: '+', key: 'add_bridge', tooltip: 'Add Bridge', minWidth: 30 },
  { name: 'Comments', key: 'comments', minWidth: 200, width: '-webkit-fill-available' },
  { name: 'x', key: 'delete', tooltip: 'Delete Token', minWidth: 30 },
];

const getSlippageNum = (value: string | number) => {
  const str = value.toString();
  if (str.endsWith('%')) {
    return parseFloat(str.slice(0, str.length - 1));
  }
  return parseFloat(str);
};

/**
 *
 */
type SettingsTokenTableProps = {
  tokens: DBTokens
  bridges: DBBridges
  textFilter: string
  filterData: SettingsTokensFilterData
  sortingData: SettingsTokensSortingData
  miscSettings?: DBMiscSettings
}
export default memo(function SettingsTokenTable({
                                                  tokens,
                                                  bridges,
                                                  textFilter,
                                                  filterData,
                                                  sortingData,
                                                  miscSettings,
                                                }: SettingsTokenTableProps) {
  const tokenEntries = Object.entries(tokens);
  const virtualTableRef = useRef<VirtualTableRefProps>(null);

  /**
   * Filtering
   */
  const filteredTokenEntries = useMemo(() => {
    return tokenEntries.filter(([tokenName, tokenData]) => {
      const { networkData, active, automation, hidden, comments, bridge } = tokenData;
      const text = textFilter.toLowerCase();

      const bridgeNames = Object.keys(bridge || {});

      const textCondition =
        textFilter === '' ||
        tokenName.toLowerCase().includes(text) ||
        bridgeNames.join(',').toLowerCase().includes(text) ||
        Object.values(networkData ?? {}).some((networkData) =>
          networkData?.address.toLowerCase().includes(text),
        );

      const enabledCondition =
        filterData.tokenEnabled === 'none' ||
        (filterData.tokenEnabled === 'true' && active) ||
        (filterData.tokenEnabled === 'false' && !active);

      const automationCondition =
        filterData.tokenAutomation === 'none' ||
        (filterData.tokenAutomation === 'true' && automation) ||
        (filterData.tokenAutomation === 'false' && !automation);

      const hiddenCondition =
        filterData.tokenHidden === 'none' ||
        (filterData.tokenHidden === 'true' && hidden === true) ||
        (filterData.tokenHidden === 'false' && !hidden);

      const bridgeCondition =
        filterData.tokenBridge === 'none' ||
        (filterData.tokenBridge === 'true' && Object.keys(bridge || {}).length > 0) ||
        (filterData.tokenBridge === 'false' && Object.keys(bridge || {}).length === 0);

      const commentsCondition =
        filterData.tokenComments === 'none' ||
        (filterData.tokenComments === 'true' && comments) ||
        (filterData.tokenComments === 'false' && !comments);

      const missingDataCondition =
        filterData.hasMissingData === 'none' ||
        (filterData.hasMissingData === 'true' &&
          Object.values(networkData ?? {}).some((nd) => nd?.address === '0x0')) ||
        (filterData.hasMissingData === 'false' &&
          Object.values(networkData ?? {}).every((nd) => nd?.address !== '0x0'));

      return (
        textCondition &&
        enabledCondition &&
        automationCondition &&
        hiddenCondition &&
        bridgeCondition &&
        commentsCondition &&
        missingDataCondition
      );
    });
  }, [tokenEntries, textFilter, filterData]);

  /**
   * Sorting
   */
  filteredTokenEntries.sort((a, b) => {
    if (sortingData.sortBy === 'tokenName') {
      return sortingData.sortOrder === 'asc' ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0]);
    }
    if (sortingData.sortBy === 'slippage') {
      return sortingData.sortOrder === 'asc'
        ? getSlippageNum(a[1].slippage) - getSlippageNum(b[1].slippage)
        : getSlippageNum(b[1].slippage) - getSlippageNum(a[1].slippage);
    }
    return 0;
  });

  const data = filteredTokenEntries.map(([tokenName, tokenData]) => {
    return [
      <EnabledCell tokenName={tokenName} active={tokenData.active} />,
      <TokenCell tokenName={tokenName} />,
      <AutomationCell tokenName={tokenName} automation={tokenData.automation} />,
      <HideTokenCell tokenName={tokenName} isHidden={tokenData.hidden === true} />,
      <NetworkCell
        tokenName={tokenName}
        networkData={tokenData.networkData}
        networkTrackIgnore={tokenData.networkTrackIgnore}
      />,
      <AddressCell tokenName={tokenName} networkData={tokenData.networkData} />,
      <DecimalsCell tokenName={tokenName} networkData={tokenData.networkData} />,
      <ExpensiveBribeCell tokenName={tokenName} value={tokenData.expensiveBribe ?? false} />,
      <CheapBribeCell tokenName={tokenName} value={tokenData.cheapBribe ?? false} />,
      <AddNetworkCell tokenName={tokenName} networkData={tokenData.networkData} />,
      <SelectedRequestersCell
        tokenName={tokenName}
        selectedRequesters={tokenData.selectedRequesters}
        miscSettings={miscSettings}
      />,
      <TrackingValueCell tokenName={tokenName} trackingValue={tokenData.trackingValue} />,
      <MinTrackingValueCell tokenName={tokenName} minValue={tokenData.minTrackingValue} />,
      <MaxTrackingValueCell tokenName={tokenName} maxValue={tokenData.maxTrackingValue} />,
      <SlippageCell tokenName={tokenName} slippage={tokenData.slippage} />,
      <TradePairSlippageCell
        tokenName={tokenName}
        slippage={tokenData.slippage}
        tradeSlippage={tokenData.tradeSlippage}
      />,
      <AddTradePairSlippageCell
        tokenName={tokenName}
        networkData={tokenData.networkData}
        tradeSlippage={tokenData.tradeSlippage}
      />,
      <MinProfitCell tokenName={tokenName} minRequiredProfit={tokenData.minRequiredProfit} />,
      <AddMinProfitCell
        tokenName={tokenName}
        networkData={tokenData.networkData}
        minRequiredProfit={tokenData.minRequiredProfit}
      />,
      // <NetworkPairSlippageCell
      // 	tokenName={tokenName}
      // 	slippage={tokenData.slippage}
      // 	networkSlippage={tokenData.networkPairSlippage}
      // />,
      // <AddNetworkPairSlippageCell
      // 	tokenName={tokenName}
      // 	networkData={tokenData.networkData}
      // 	networkSlippage={tokenData.networkPairSlippage}
      // />,
      // <BridgeNameCell tokenName={tokenName} bridgeName={tokenData.bridgeName} />,
      <BridgeCell tokenName={tokenName} tokenBridge={tokenData.bridge} />,
      <BridgeNetworksCell tokenName={tokenName} tokenBridge={tokenData.bridge} />,
      <AddBridgeCell tokenName={tokenName} tokenBridge={tokenData.bridge} bridges={bridges} />,
      <CommentsCell tokenName={tokenName} comments={tokenData.comments} />,
      <DeleteCell tokenName={tokenName} />,
    ];
  });

  useEffect(() => {
    virtualTableRef.current?.measure();
  }, [filterData, filteredTokenEntries]);

  const totalNumTokens = tokenEntries.length;
  const filteredNumTokens = filteredTokenEntries.length;

  const estimateRowHeight = useCallback(
    (i: number) => {
      if (totalNumTokens === 0) return 0;

      const [tokenName, tokenData] = filteredTokenEntries[i];
      const numNetworks = Object.keys(tokenData.networkData ?? {}).length;
      const numBridges = Object.keys(tokenData.bridge ?? {}).length;
      const maxNumRows = Math.max(numNetworks, numBridges);

      let base = 8;
      if (maxNumRows === 0) {
        return base + 29 + 4;
      }
      base += maxNumRows * (29 + 4);

      return base;
    },
    [filteredTokenEntries, totalNumTokens],
  );

  return (
    <Stack gap={2}>
      <div>
        <Badge bg="light" text="dark" className="h1" style={{ fontSize: '0.8em' }}>
          {`Showing ${filteredNumTokens} / ${totalNumTokens} entries`}
        </Badge>
      </div>
      <VirtualTable
        ref={virtualTableRef}
        columns={columns}
        data={data}
        style={{ fontFamily: 'monospace' }}
        estimateRowHeight={estimateRowHeight}
      />
    </Stack>
  );
});

/**
 *
 */
function sortAlphabetical(values: string[]): string[] {
  const sorted = structuredClone(values);
  sorted.sort((a: string, b: string) => a.localeCompare(b));
  return sorted;
}

/**
 *
 */
type EnabledCellProps = {
  tokenName: string
  active: DBToken['active']
}
const EnabledCell = memo(function EnabledCell({ tokenName, active }: EnabledCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" onClick={() => updateTokenActive(tokenName, active)}>
        {active ? '🟢' : '🔴'}
      </Button>
    </div>
  );
});

/**
 *
 */
type TokenCellProps = {
  tokenName: string
}
const TokenCell = memo(function TokenCell({ tokenName }: TokenCellProps) {
  return (
    <Badge bg="light" text="dark" className="noBg" style={{ position: 'relative', fontSize: '1em' }}>
      {tokenName}
    </Badge>
  );
});

/**
 *
 */
type AutomationCellProps = {
  tokenName: string
  automation: DBToken['automation']
}
const AutomationCell = memo(function AutomationCell({ tokenName, automation }: AutomationCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" onClick={() => updateTokenAutomation(tokenName, automation)}>
        {automation ? '✅' : '⬛️'}
      </Button>
    </div>
  );
});

/**
 *
 */
type HideTokenCellProps = {
  tokenName: string
  isHidden: boolean
}
const HideTokenCell = memo(function HideTokenCell({ tokenName, isHidden }: HideTokenCellProps) {
  const ifHidden = (
    <Button size="sm" variant="light" onClick={() => updateTokenHidden(tokenName, null)}>
      {eyeSlash}
    </Button>
  );

  const [hover, setHover] = useState(false);
  const ifNotHidden = (
    <Button
      size="sm"
      variant="outline-light"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => updateTokenHidden(tokenName, true)}
    >
      {hover && eyeSlash}
    </Button>
  );

  return (
    <div className="d-grid" style={{ height: '100%' }}>
      {isHidden && ifHidden}
      {!isHidden && ifNotHidden}
    </div>
  );
});

/**
 *
 */
type NetworkCellProps = {
  tokenName: string
  networkData: DBToken['networkData']
  networkTrackIgnore: DBToken['networkTrackIgnore']
}
const NetworkCell = memo(function NetworkCell({
                                                tokenName,
                                                networkData,
                                                networkTrackIgnore,
                                              }: NetworkCellProps) {
  const networkNames = Object.keys(networkData ?? {});
  const networkNamesSorted = useMemo(() => sortAlphabetical(networkNames), [networkNames]);

  return (
    <Stack gap={1}>
      {networkNamesSorted.map((_networkName) => {
        const networkName = _networkName as NETWORK;

        const isTrackIgnored = getTokenNetworkTrackIgnore(networkTrackIgnore, networkName);

        return (
          <div key={networkName} className="d-grid">
            <Button
              size="sm"
              variant="light"
              className="noBgHov noBorder"
              style={{ position: 'relative' }}
              onClick={(e) => {
                if (e.metaKey && e.shiftKey) {
                  return toggleTokenNetworkTrackIgnore(
                    tokenName,
                    networkTrackIgnore,
                    networkName,
                  );
                }
                deleteTokenNetwork(tokenName, networkName as NETWORK, networkData);
              }}
            >
              {isTrackIgnored && (
                <div
                  style={{
                    position: 'absolute',
                    fontSize: '0.7em',
                    width: '100%',
                    left: '4px',
                  }}
                >
                  ❌
                </div>
              )}
              {/* {networkName} */}
              {/* <div style={{ display: 'inline-block', float: 'left' }}> */}
              <img src={getNetworkImage(networkName)} width="14px" alt="" />
              {/* </div> */}
            </Button>
          </div>
        );
      })}
    </Stack>
  );
});

/**
 *
 */
type ExpensiveBribeCellProps = {
  tokenName: string
  value: boolean
}
const ExpensiveBribeCell = memo(function ExpensiveBribeCell({ tokenName, value }: ExpensiveBribeCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" onClick={() => updateTokenExpensiveBribe(tokenName, value)}>
        {value ? '✅' : '⬛️'}
      </Button>
    </div>
  );
});

/**
 *
 */
type CheapBribeCellProps = {
  tokenName: string
  value: boolean
}
const CheapBribeCell = memo(function CheapBribeCell({ tokenName, value }: CheapBribeCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" onClick={() => updateTokenCheapBribe(tokenName, value)}>
        {value ? '✅' : '⬛️'}
      </Button>
    </div>
  );
});

/**
 *
 */
type AddNetworkCellProps = {
  tokenName: string
  networkData: DBToken['networkData']
}
const AddNetworkCell = memo(function AddNetworkCell({ tokenName, networkData }: AddNetworkCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        onClick={() => {
          addTokenNetwork(tokenName, networkData);
        }}
      >
        {'+'}
      </Button>
    </div>
  );
});

/**
 *
 */
type SelectedRequestersCellProps = {
  tokenName: string
  selectedRequesters: DBToken['selectedRequesters']
  miscSettings?: DBMiscSettings
}
const SelectedRequestersCell = memo(function SelectedRequestersCell({
                                                                      tokenName,
                                                                      selectedRequesters,
                                                                      miscSettings,
                                                                    }: SelectedRequestersCellProps) {
  const selected = Object.entries(selectedRequesters || {}).filter(([r, v]) => v === true);
  const numSelected = selected.length;
  const maxSelected = Object.values(REQUESTER).filter(
    // (r) => miscSettings?.requesterSettings[r]?.tracking === "selected"
    (r) => true,
  ).length;
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Stack direction="horizontal" gap={1}>
        {selected.map(([r]) => {
          return (
            <Button
              size="sm"
              key={r}
              variant="light"
              onClick={() =>
                deleteTokenSelectedRequester(tokenName, r as REQUESTER, selectedRequesters)
              }
            >
              <img src={getAggregatorImage(r)} width="14px" alt="" />
            </Button>
          );
        })}
        {numSelected < maxSelected && (
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              addTokenSelectedRequester(tokenName, selectedRequesters, miscSettings);
            }}
          >
            {'+'}
          </Button>
        )}
      </Stack>
    </div>
  );
});

/**
 *
 */
type AddressCellProps = {
  tokenName: string
  networkData: DBToken['networkData']
}
const AddressCell = memo(function AddressCell({ tokenName, networkData }: AddressCellProps) {
  const networkNames = Object.keys(networkData ?? {});
  const networkNamesSorted = useMemo(() => sortAlphabetical(networkNames), [networkNames]);

  return (
    <Stack gap={1}>
      {networkNamesSorted.map((_networkName) => {
        const networkName = _networkName as NETWORK;
        const address = networkData?.[networkName]?.address;
        return (
          <div key={networkName} className="d-grid">
            <Button
              size="sm"
              variant="light"
              className="noBgHov noBorder text-truncate"
              onClick={(e) => {
                if (e.altKey || e.metaKey) {
                  return window.open(
                    `${NETWORK_EXPLORER_ADDRESS[networkName]}/token/${address}`,
                  );
                }
                updateTokenNetworkAddress(tokenName, networkName as NETWORK, networkData);
              }}
            >
              {address && address.length > 10 ? address.slice(0, 6) + '...' + address.slice(-4) : address}
            </Button>
          </div>
        );
      })}
    </Stack>
  );
});

/**
 *
 */
type DecimalsCellProps = {
  tokenName: string
  networkData: DBToken['networkData']
}
const DecimalsCell = memo(function DecimalsCell({ tokenName, networkData }: DecimalsCellProps) {
  const networkNames = Object.keys(networkData ?? {});
  const networkNamesSorted = useMemo(() => sortAlphabetical(networkNames), [networkNames]);

  return (
    <Stack gap={1}>
      {networkNamesSorted.map((networkName) => (
        <div key={networkName} className="d-grid">
          <Button
            size="sm"
            variant="light"
            className="noBgHov noBorder"
            onClick={() =>
              updateTokenNetworkDecimals(tokenName, networkName as NETWORK, networkData)
            }
          >
            {networkData?.[networkName as NETWORK]?.decimals}
          </Button>
        </div>
      ))}
    </Stack>
  );
});

/**
 *
 */
type TrackingValueCellProps = {
  tokenName: string
  trackingValue: DBToken['trackingValue']
}
const TrackingValueCell = memo(function TrackingValueCell({
                                                            tokenName,
                                                            trackingValue,
                                                          }: TrackingValueCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        className="noBgHov noBorder"
        style={{ color: '#afafaf' }}
        onClick={() => updateTokenTrackingValue(tokenName, trackingValue)}
      >
        {trackingValue}
      </Button>
    </div>
  );
});

/**
 *
 */
type MinTrackingValueCellProps = {
  tokenName: string
  minValue: DBToken['minTrackingValue']
}
const MinTrackingValueCell = memo(function MinTrackingValueCell({
                                                                  tokenName,
                                                                  minValue,
                                                                }: MinTrackingValueCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        className="noBgHov noBorder"
        onClick={() => updateTokenMinTrackingValue(tokenName, minValue)}
      >
        {minValue === undefined || minValue === 0 ? '' : minValue}
      </Button>
    </div>
  );
});

/**
 *
 */
type MaxTrackingValueCellProps = {
  tokenName: string
  maxValue: DBToken['maxTrackingValue']
}
const MaxTrackingValueCell = memo(function MaxTrackingValueCell({
                                                                  tokenName,
                                                                  maxValue,
                                                                }: MaxTrackingValueCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        className="noBgHov noBorder"
        onClick={() => updateTokenMaxTrackingValue(tokenName, maxValue)}
      >
        {maxValue === undefined || maxValue === 0 ? '' : maxValue}
      </Button>
    </div>
  );
});

/**
 *
 */
type SlippageCellProps = {
  tokenName: string
  slippage: DBToken['slippage']
}
const SlippageCell = memo(function SlippageCell({ tokenName, slippage }: SlippageCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        className="noBgHov noBorder"
        onClick={() => updateTokenSlippage(tokenName, slippage)}
      >
        {slippage}
      </Button>
    </div>
  );
});

/**
 *
 */
type TradePairSlippageCellProps = {
  tokenName: string
  slippage: DBToken['slippage']
  tradeSlippage: DBToken['tradeSlippage']
}
const TradePairSlippageCell = memo(function TradePairSlippageCell({
                                                                    tokenName,
                                                                    slippage,
                                                                    tradeSlippage,
                                                                  }: TradePairSlippageCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Stack gap={1}>
        {Object.entries(tradeSlippage || {}).map(([networkName, val]) => {
          return (
            <Stack key={networkName} direction="horizontal" gap={1}>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() =>
                  deleteTokenTradeSlippage(tokenName, networkName, tradeSlippage)
                }
              >
                <img src={getNetworkImage(networkName)} width="14px" alt="" />
              </Button>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() =>
                  editTokenTradeSlippage(tokenName, networkName as NETWORK, tradeSlippage)
                }
              >
                {val ? val : 0}
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
});

/**
 *
 */
type AddTradePairSlippageCellProps = {
  tokenName: string
  networkData: DBToken['networkData']
  tradeSlippage: DBToken['tradeSlippage']
}
const AddTradePairSlippageCell = memo(function AddTradePairSlippageCell({
                                                                          tokenName,
                                                                          networkData,
                                                                          tradeSlippage,
                                                                        }: AddTradePairSlippageCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        onClick={() => addTokenTradeSlippage(tokenName, networkData, tradeSlippage)}
      >
        {'+'}
      </Button>
    </div>
  );
});

/**
 *
 */
type MinProfitCellProps = {
  tokenName: string,
  minRequiredProfit: DBToken['minRequiredProfit']
}
const MinProfitCell = memo(function MinProfitCell({ tokenName, minRequiredProfit }: MinProfitCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Stack gap={1}>
        {Object.entries(minRequiredProfit || {}).map(([networkPair, minProfit]) => {
          const [networkBuy, networkSell] = networkPair.split('_') as [NETWORK, NETWORK];
          return (
            <Stack key={networkPair} direction="horizontal" gap={1}>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() =>
                  deleteTokenMinProfit(tokenName, networkPair, minRequiredProfit)
                }
              >
                <img src={getNetworkImage(networkBuy)} width="14px" alt="" />
              </Button>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() =>
                  deleteTokenMinProfit(tokenName, networkPair, minRequiredProfit)
                }
              >
                <img src={getNetworkImage(networkSell)} width="14px" alt="" />
              </Button>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() =>
                  editTokenMinProfit(tokenName, networkPair, minRequiredProfit)
                }
              >
                {minProfit ?? 0}
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
});

/**
 *
 */
type AddMinProfitCellProps = {
  tokenName: string,
  networkData: DBToken['networkData'],
  minRequiredProfit: DBToken['minRequiredProfit']
}
const AddMinProfitCell = memo(function AddMinProfitCell(
  {
    tokenName,
    networkData,
    minRequiredProfit,
  }: AddMinProfitCellProps) {
  const networks = Object.keys(networkData ?? {}) as NETWORK[];
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        onClick={() => addTokenMinProfit(tokenName, networks, minRequiredProfit)}
      >
        {'+'}
      </Button>
    </div>
  );
});

/**
 *
 */
type NetworkPairSlippageCellProps = {
  tokenName: string
  slippage: DBToken['slippage']
  networkSlippage: DBToken['networkPairSlippage']
}
const NetworkPairSlippageCell = memo(function NetworkPairSlippageCell({
                                                                        tokenName,
                                                                        slippage,
                                                                        networkSlippage,
                                                                      }: NetworkPairSlippageCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Stack gap={1}>
        {Object.entries(networkSlippage || {}).map(([key, nval]) => {
          const [n1, n2] = key.split('_');
          const n1Val = (nval || {})[n1 as keyof typeof nval] ?? -1;
          const n2Val = (nval || {})[n2 as keyof typeof nval] ?? -1;
          return (
            <Stack
              key={key}
              direction="horizontal"
              style={{ justifyContent: 'space-between' }}
              gap={1}
            >
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() => deleteTokenNetworkSlippage(tokenName, key, networkSlippage)}
              >
                <img src={getNetworkImage(n1)} width="14px" alt="" />
              </Button>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                style={{ color: n1Val === -1 ? '#afafaf' : '#000' }}
                onClick={() =>
                  editTokenNetworkSlippage(tokenName, key, n1 as NETWORK, networkSlippage)
                }
              >
                {n1Val === -1 ? slippage : n1Val}
              </Button>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                style={{ color: n2Val === -1 ? '#afafaf' : '#000' }}
                onClick={() =>
                  editTokenNetworkSlippage(tokenName, key, n2 as NETWORK, networkSlippage)
                }
              >
                {n2Val === -1 ? slippage : n2Val}
              </Button>
              <Button
                size="sm"
                variant="light"
                className="noBgHov noBorder"
                onClick={() => deleteTokenNetworkSlippage(tokenName, key, networkSlippage)}
              >
                <img src={getNetworkImage(n2)} width="14px" alt="" />
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
});

/**
 *
 */
type AddNetworkPairSlippageCellProps = {
  tokenName: string
  networkData: DBToken['networkData']
  networkSlippage: DBToken['networkPairSlippage']
}
const AddNetworkPairSlippageCell = memo(function AddNetworkPairSlippageCell({
                                                                              tokenName,
                                                                              networkData,
                                                                              networkSlippage,
                                                                            }: AddNetworkPairSlippageCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        onClick={() => addTokenNetworkSlippage(tokenName, networkData, networkSlippage)}
      >
        {'+'}
      </Button>
    </div>
  );
});

/**
 *
 */
type BridgeNameCellProps = {
  tokenName: string
  bridgeName: DBToken['bridgeName']
}
const BridgeNameCell = memo(function BridgeNameCell({ tokenName, bridgeName }: BridgeNameCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" className="noBgHov noBorder" style={{ color: '#969696' }}>
        {bridgeName}
      </Button>
    </div>
  );
});

/**
 *
 */
type BridgeCellProps = {
  tokenName: string
  tokenBridge: DBToken['bridge']
}
const BridgeCell = memo(function BridgeCell({ tokenName, tokenBridge }: BridgeCellProps) {
  const bridgeNames = Object.keys(tokenBridge ?? {});
  const bridgeNamesSorted = useMemo(() => sortAlphabetical(bridgeNames), [bridgeNames]);
  const bridgeNamesSortedByPriority = bridgeNamesSorted.sort((a, b) => {
    return (tokenBridge || {})[a].priority - (tokenBridge || {})[b].priority;
  });

  return (
    <Stack gap={1}>
      {bridgeNamesSortedByPriority.map((bridgeName) => {
        return (
          <Stack key={bridgeName} direction="horizontal" gap={1}>
            <Button
              size="sm"
              variant="light"
              className="noBgHov"
              onClick={() => updateBridgeAutomation(tokenName, bridgeName, tokenBridge)}
            >
              {tokenBridge?.[bridgeName]?.automation ?? false ? '🟢' : '🔴'}
            </Button>
            <Button
              size="sm"
              variant="light"
              className="noBgHov"
              style={{ position: 'relative' }}
              onClick={() => {
              }}
            >
              {tokenBridge?.[bridgeName]?.priority ?? 0}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '50%',
                }}
                onClick={() =>
                  updateBridgePriority(tokenName, bridgeName, tokenBridge, 'dec')
                }
              ></div>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '50%',
                }}
                onClick={() =>
                  updateBridgePriority(tokenName, bridgeName, tokenBridge, 'inc')
                }
              ></div>
            </Button>
            <Button
              size="sm"
              variant="light"
              className="noBgHov text-truncate"
              style={{ width: '130px' }}
              onClick={() => deleteTokenBridge(tokenName, bridgeName, tokenBridge)}
            >
              {bridgeName}
            </Button>
          </Stack>
        );
      })}
    </Stack>
  );
});

/**
 *
 */
type BridgeNetworksCellProps = {
  tokenName: string
  tokenBridge: DBToken['bridge']
}
const BridgeNetworksCell = memo(function BridgeNetworksCell({
                                                              tokenName,
                                                              tokenBridge,
                                                            }: BridgeNetworksCellProps) {
  const bridgeNames = Object.keys(tokenBridge ?? {});
  const bridgeNamesSorted = useMemo(() => sortAlphabetical(bridgeNames), [bridgeNames]);
  const bridgeNamesSortedByPriority = bridgeNamesSorted.sort((a, b) => {
    return (tokenBridge || {})[a].priority - (tokenBridge || {})[b].priority;
  });

  return (
    <Stack gap={1}>
      {bridgeNamesSortedByPriority.map((bridgeName) => {
        const bridgeNetworks = tokenBridge?.[bridgeName]?.networks ?? [];
        return (
          <Stack key={bridgeName} direction="horizontal" gap={1}>
            {bridgeNetworks.map((networkName, i) => {
              return (
                <Button
                  size="sm"
                  key={networkName + i}
                  variant="light"
                  onClick={() =>
                    deleteTokenBridgeNetwork(
                      tokenName,
                      bridgeName,
                      networkName,
                      tokenBridge,
                    )
                  }
                >
                  <img src={getNetworkImage(networkName)} width="14px" alt="" />
                  {/* {networkName} */}
                </Button>
              );
            })}
            {bridgeNetworks.length < Object.keys(NETWORK).length && (
              <Button
                size="sm"
                variant="light"
                onClick={() => addTokenBridgeNetwork(tokenName, bridgeName, tokenBridge)}
              >
                {'+'}
              </Button>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
});

/**
 *
 */
type AddBridgeCellProps = {
  tokenName: string
  tokenBridge: DBToken['bridge']
  bridges: DBBridges
}
const AddBridgeCell = memo(function AddBridgeCell({ tokenName, tokenBridge, bridges }: AddBridgeCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" onClick={() => addTokenBridge(tokenName, tokenBridge, bridges)}>
        {'+'}
      </Button>
    </div>
  );
});

/**
 *
 */
type CommentsCellProps = {
  tokenName: string
  comments: DBToken['comments']
}
const CommentsCell = memo(function CommentsCell({ tokenName, comments }: CommentsCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button
        size="sm"
        variant="light"
        className="noBgHov noBorder text-truncate"
        onClick={() => updateTokenComments(tokenName, comments)}
      >
        {comments || '‎ '}
      </Button>
    </div>
  );
});

/**
 *
 */
type DeleteCellProps = {
  tokenName: string
}
const DeleteCell = memo(function DeleteCell({ tokenName }: DeleteCellProps) {
  return (
    <div className="d-grid" style={{ height: '100%' }}>
      <Button size="sm" variant="light" onClick={() => deleteToken(tokenName)}>
        {'x'}
      </Button>
    </div>
  );
});
