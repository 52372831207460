import {
	DBResolved,
	DBResolvedPools,
	DBResolvedTokens,
	RoutesData,
	Sell,
	WorkerIterationErrors,
	WorkerIterationResult,
	WorkerIterationResults,
} from "../types"

/**
 *
 */
export enum WORKER_EVENTS {
	WORKER_SUBMIT_FOUND_ROUTES = "WORKER_SUBMIT_FOUND_ROUTES",
	WORKER_SUBMIT_EXTRACTED_POOL_DATA = "WORKER_SUBMIT_EXTRACTED_POOL_DATA",

	WORKER_ROUTE_SEARCH_PROGRESS = "WORKER_ROUTE_SEARCH_PROGRESS",

	WORKER_STATS_STRING = "WORKER_STATS_STRING",
	WORKER_ANALYTICS_STRING = "WORKER_ANALYTICS_STRING",
	WORKER_EVENTS_STRING = "WORKER_EVENTS_STRING",

	WORKER_LP_STAT_STRING = "WORKER_LP_STAT_STRING",

	WORKER_ITERATION_RESULT = "WORKER_ITERATION_RESULT",
	WORKER_ITERATION_ERROR = "WORKER_ITERATION_ERROR",
	WORKER_AUTOSELL_ITERATION = "WORKER_AUTOSELL_ITERATION",
	WORKER_AUTOSELL_ITERATION_RESULT = "WORKER_AUTOSELL_ITERATION_RESULT",
}

/**
 *
 */
export type WORKER_EVENTS_PARAMS = {
	[WORKER_EVENTS.WORKER_SUBMIT_FOUND_ROUTES]: RoutesData
	[WORKER_EVENTS.WORKER_SUBMIT_EXTRACTED_POOL_DATA]: DBResolved

	[WORKER_EVENTS.WORKER_ROUTE_SEARCH_PROGRESS]: {
		running: boolean
		progress: {
			[key: string]: number
		}
	}

	[WORKER_EVENTS.WORKER_STATS_STRING]: string
	[WORKER_EVENTS.WORKER_ANALYTICS_STRING]: string
	[WORKER_EVENTS.WORKER_EVENTS_STRING]: string
	[WORKER_EVENTS.WORKER_LP_STAT_STRING]: string

	[WORKER_EVENTS.WORKER_ITERATION_RESULT]: WorkerIterationResults
	[WORKER_EVENTS.WORKER_ITERATION_ERROR]: WorkerIterationErrors
	[WORKER_EVENTS.WORKER_AUTOSELL_ITERATION]: Sell
	[WORKER_EVENTS.WORKER_AUTOSELL_ITERATION_RESULT]: false | { res: WorkerIterationResult; tradeId: string }
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
	[Property in WORKER_EVENTS]: WORKER_EVENTS_PARAMS[Property]
}
